<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

//PrimeVue
import { default as PInputText } from 'primevue/inputtext'
import { default as PDropdown } from 'primevue/dropdown'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { default as PInlineMessage } from 'primevue/inlinemessage'

//Vue, Vue Router etc
import { computed, onMounted, type Ref, ref } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

// Custom components
import LearningHeader from '@/views/learningCourses/blocks/LearningHeader.vue'
import { LearningCourseInputDto } from '@/assets/DTO/learning/learningCourse.dto'
import { LearningEnums } from '@/assets/types/learning/enums'
import { useAxisesOptions } from '@/assets/utils/dataOptions'

//Store
import { useReferentialStore } from '@/stores/referential'
import { useLearningCoursesStore } from '@/stores/learningCourses'
import { LearningCourseNamesResponseDto } from '@/assets/DTO/learning/learningCourse.response.dto'

const { referential } = storeToRefs(useReferentialStore())
const { createLearningCourse, getAllLearningCourseNames, learningCoursesCount } =
  useLearningCoursesStore()

const router = useRouter()
// Data, refs
const loading: Ref<boolean> = ref(true)
const learningCourseNames = ref(new LearningCourseNamesResponseDto({ names: [] }))
const input = ref(
  new LearningCourseInputDto({
    id: 0,
    title: '',
    order: learningCoursesCount + 1,
    name: '',
    status: LearningEnums.Status.DRAFT,
    domainId: undefined
  })
)

const nameExists: Ref<boolean> = ref(false)

const selectedAxis: Ref<number | null> = ref(null)

// Computed
const domainOptions = computed(() => {
  if (selectedAxis.value) {
    const axis = referential.value.find((axis) => axis.id === selectedAxis.value)

    if (axis) {
      const domains = axis.domains

      if (domains)
        return domains.map((domain) => ({
          label: domain.title,
          value: domain.id
        }))
    }
  }
  return []
})

//Lifecycle hooks
onMounted(async () => {
  learningCourseNames.value = await getAllLearningCourseNames()
  loading.value = false
})

// Methods
async function save() {
  await createLearningCourse(input.value)
}

function cancel() {
  router.push({ name: 'learning-courses-list' })
}

function handleNameExists() {
  nameExists.value = learningCourseNames.value.exists(input.value.name.toLowerCase())
}
</script>

<template>
  <p-progress-spinner v-if="loading" />
  <div class="grid grid-nogutter">
    <learning-header
      title="Ajouter un parcours"
      :primary-button="{ label: 'Enregistrer', onClick: save }"
      :secondary-button="{ label: 'Annuler', onClick: () => cancel() }"
      :back-to="{
        label: 'Revenir à la liste des parcours',
        route: { name: 'learning-courses-list' }
      }"
    />

    <div class="col-4 px-5 mt-3">
      <form class="flex flex-column gap-5">
        <div class="flex flex-column gap-2">
          <label> Nom du parcours* </label>
          <p-input-text
            v-model="input.title"
            placeholder="Nom du parcours"
            type="text"
            autocomplete="off"
            data-form-type="other"
            required
          />
        </div>

        <div class="flex flex-column gap-2">
          <label for="exploration-name"> Nom de l'exploration* </label>
          <p-input-text
            id="exploration-name"
            v-model="input.name"
            placeholder="Nom de l'exploration"
            type="text"
            autocomplete="off"
            data-form-type="other"
            @input="handleNameExists"
            required
          />
          <p-inline-message v-if="nameExists">Ce nom d'exploration existe déjà !</p-inline-message>
        </div>

        <div class="flex flex-column gap-2">
          <label> Axe* </label>
          <p-dropdown
            :options="useAxisesOptions"
            v-model="selectedAxis"
            option-label="name"
            option-value="code"
            placeholder="selectionner un axe"
          />
        </div>

        <div class="flex flex-column gap-2">
          <label> Domaine* </label>
          <p-dropdown
            v-model="input.domainId"
            :options="domainOptions"
            :disabled="!selectedAxis"
            option-label="label"
            option-value="value"
            placeholder="Sélectionner un domaine"
          />
        </div>
      </form>
    </div>
  </div>
</template>
