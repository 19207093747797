<script setup lang="ts">
import GroupProgressDiagnostic from '@/views/dashboard/blocks/progress/GroupProgressDiagnostic.vue'
import { onMounted, onUnmounted, ref } from 'vue'
import { useSchoolClassroomStore } from '@/stores/schoolClassroom'
import { storeToRefs } from 'pinia'
import { useLearningCoursesStore } from '@/stores/learningCourses'
import { LearningCourseResponseDto } from '@/assets/DTO/learning/learningCourse.response.dto'

const {
  fetchSchoolClassroomById,
  fetchSchoolClassroomScoreDiagnostic,
  fetchSchoolClassroomScoreModule,
  resetScoreDiagnostic,
  resetScoreModule
} = useSchoolClassroomStore()
const { classroom, scoreDiagnostic, scoreModule } = storeToRefs(useSchoolClassroomStore())
const { fetchCourse } = useLearningCoursesStore()

// Inherit from router
const props = defineProps<{
  schoolId: number
  groupId: number
  courseId: number
}>()

const Header = ref<HTMLElement | null>(null)
const learningCourse = ref<LearningCourseResponseDto>({} as LearningCourseResponseDto)

onMounted(async () => {
  try {
    await Promise.all([
      fetchSchoolClassroomById(props.schoolId, props.groupId),
      fetchSchoolClassroomScoreDiagnostic(props.schoolId, props.groupId, props.courseId),
      fetchSchoolClassroomScoreModule(props.schoolId, props.groupId, props.courseId)
    ])
    learningCourse.value = await fetchCourse(props.courseId)
  } catch (error) {
    console.error(error)
  }
})

onUnmounted(async () => {
  resetScoreDiagnostic()
  resetScoreModule()
})
</script>

<template>
  <div class="col-12 grid grid-nogutter p-5 py-3 border-bottom-1 border-300" ref="Header">
    <div class="col justify-content-start flex flex-column gap-2">
      <router-link
        class="flex align-items-center gap-2 w-full text-color-secondary no-underline"
        :to="{
          name: 'dashboard-group',
          params: { schoolId: props.schoolId, groupId: props.groupId }
        }"
        >{{ `< Revenir au tableau de bord du groupe "${classroom.data.name}"` }}
      </router-link>

      <h3 class="uppercase text-left">{{ classroom.data.name }} : {{ learningCourse.name }}</h3>
    </div>
  </div>

  <div
    class="col-12 p-5 bg-bo-brand-tertiary flex-grow-1 overflow-y-auto gap-5 flex flex-column"
    :style="`height: calc(100vh + 1rem - ${Header?.offsetHeight}px)`"
  >
    <group-progress-diagnostic />
  </div>
</template>
