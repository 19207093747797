import { defineStore } from 'pinia'
import type { IAxis } from '@/assets/types/Referential'
import { ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { NabooError } from '@/assets/classes/Error'

export const useReferentialStore = defineStore('referential', () => {
  //State
  const referential = ref<IAxis[]>([])

  //Mutations
  function setReferential(axes: IAxis[]) {
    referential.value = axes
  }

  //Actions
  async function fetchAllAxises() {
    // Call API to fetch all axises
    try {
      const response = await nabooApi.getAllAxises()
      setReferential(response.referential)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('useReferentialStore - fetchAllAxises')
        error.setMessage('Une erreur est survenue lors de la récupération des données.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération des données.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  }

  function getAxisTitleFromDomainId(domainId: number) {
    const axis = referential.value.find((axis) => {
      return axis.domains.some((domain) => domain.id === domainId)
    })

    if (axis) {
      return axis.title
    }

    return 'Aucun axe ne correspond à ce domaine'
  }

  return {
    referential,
    fetchAllAxises,
    getAxisTitleFromDomainId
  }
})
