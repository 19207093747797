import type { ILearningQuiz } from '@/assets/types/learning/learningQuiz'
import type { IQuestion } from '@/assets/types/learning/QuizQuestion'
import { LearningEnums } from '@/assets/types/learning/enums'

export class LearningQuizResponseDto implements ILearningQuiz {
  id: number
  type: LearningEnums.QuizType
  ready: boolean
  questions: IQuestion[]

  constructor(quiz: ILearningQuiz) {
    this.id = quiz.id
    this.type = quiz.type
    this.ready = quiz.ready
    this.questions = quiz.questions
  }
}
