import type { IUser, IUserInput } from '@/assets/types/UserTypes'
import { Gender, Role } from '@/assets/types/UserTypes'

export class UserResponseDto implements IUser {
  identifier: string
  firstname: string
  lastname: string
  nickname: string
  email: string
  isEmailVerified: boolean
  gender: Gender.Values
  roles: Role.Values[]
  birthdate?: Date
  picture?: string
  hasAnsweredTrackingConsentBanner: boolean
  hasConsentedForTracking: boolean
  isBlocked: boolean

  constructor(obj: IUser) {
    this.identifier = obj.identifier
    this.firstname = obj.firstname
    this.lastname = obj.lastname
    this.nickname = obj.nickname
    this.email = obj.email
    this.isEmailVerified = obj.isEmailVerified
    this.gender = obj.gender
    this.birthdate = obj.birthdate ? new Date(obj.birthdate) : undefined
    this.picture = obj.picture
    this.roles = obj.roles
    this.isBlocked = obj.isBlocked
    this.hasAnsweredTrackingConsentBanner = obj.hasAnsweredTrackingConsentBanner
    this.hasConsentedForTracking = obj.hasConsentedForTracking
  }

  getUserInputDto(): IUserInput {
    return {
      identifier: this.identifier,
      firstname: this.firstname,
      lastname: this.lastname,
      nickname: this.nickname,
      email: this.email,
      blocked: this.isBlocked,
      birthdate: this.birthdate?.toISOString(),
      gender: this.gender,
      roles: this.roles
    }
  }
}

export class GetUsersResponseDto {
  users: UserResponseDto[]

  constructor(obj: { users: UserResponseDto[] }) {
    this.users = obj.users
  }
}
