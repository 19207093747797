import { useAuthStore } from '@/stores/auth'
import TerminalService from 'primevue/terminalservice'

export const useCommandHandler = async (text: string) => {
  const { getAccessTokenSilently } = useAuthStore()
  let response
  const argsIndex = text.indexOf(' ')
  const command = argsIndex !== -1 ? text.substring(0, argsIndex) : text

  switch (command.trim().toLowerCase()) {
    case 'date':
      response = new Date().toLocaleString()
      break
    case 'version':
      response = __APP_VERSION__
      break
    case 'token-api':
      response = await getAccessTokenSilently()
      break
    default:
      response = `Unknown command: ${command}`
  }
  TerminalService.emit('response', response)
}
