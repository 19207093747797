<script setup lang="ts">
import { useLearningCoursesStore } from '@/stores/learningCourses'
import { computed, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { default as PTag } from 'primevue/tag'
import { default as PButton } from 'primevue/button'
import DashboardBaseBlock from '@/views/dashboard/blocks/DashboardBaseBlock.vue'

const { fetchAllCourses } = useLearningCoursesStore()
const { learningCourses } = storeToRefs(useLearningCoursesStore())

// Inherit from router
const props = defineProps<{
  schoolId: number
  groupId: number
}>()

onMounted(async () => {
  await fetchAllCourses()
})

/**
 * Get active learning courses
 */
const activeLearningCourses = computed(() => {
  return learningCourses.value.filter((course) => course.isActive)
})
</script>

<template>
  <div class="flex flex-column grid col-12">
    <dashboard-base-block title="Progression du groupe">
      <div
        v-for="course in activeLearningCourses"
        :key="`course_${course.id}`"
        class="col-12 flex flex-column gap-3 align-items-start mb-5"
      >
        <div class="flex gap-5">
          <h4>{{ course.title }}</h4>
          <p-tag class="bg-orange-400 text-orange-900 uppercase py-1" rounded
            >Exploration {{ course.name }}
          </p-tag>
        </div>

        <router-link
          class="bo-active-color text-right no-underline"
          :to="{
            name: 'dashboard-group-progress',
            params: { schoolId: props.schoolId, groupId: props.groupId, courseId: course.id }
          }"
        >
          <p-button outlined severity="primary" label="Voir les détails et résultats"></p-button>
        </router-link>
      </div>
    </dashboard-base-block>
  </div>
</template>
