// Class for error handling

import type { IErrorInput } from '@/assets/types/ErrorTypes'

declare global {
  interface ErrorConstructor {
    captureStackTrace(targetObject: Object, constructorOpt?: Function): void
  }
}

export class NabooError extends Error {
  // Error message
  message: string
  // Error status
  private status: number
  // Error code
  private code: string
  //Error stack
  stack?: string
  //Error details
  private errorDetails?: any
  // Error constructor
  constructor({ message, status, name, code, errorDetails, error }: IErrorInput) {
    super(message)
    this.message = message
    this.status = status
    this.name = name
    this.code = code
    this.stack = error.stack
    this.errorDetails = errorDetails
  }
  // Function to get the error message
  getMessage(): string {
    return this.message
  }
  // Function to get the error status
  getStatus(): number {
    return this.status
  }
  // Function to get the error name
  getName(): string {
    return this.name
  }
  // Function to get the error code
  getCode(): string {
    return this.code
  }
  // Function to get the error stack
  getStack(): string {
    return this.stack ? this.stack : 'No stack available.'
  }
  // Function to set the error message
  setMessage(message: string): void {
    this.message = message
  }
  // Function to set the error status
  setStatus(status: number): void {
    this.status = status
  }
  // Function to set the error name
  setName(name: string): void {
    this.name = name
  }
  // Function to set the error code
  setCode(code: string): void {
    this.code = code
  }
  // Function to set the error stack
  setStack(stack: string): void {
    this.stack = stack
  }
  // Function to get the error details
  getErrorDetails(): string {
    return `Error: ${this.name} - Status: ${this.status} - Message: ${this.message}`
  }
  // Function to get the error details
  getErrorDetailsWithDate(): string {
    return `Error: ${this.name} - Status: ${this.status} - Message: ${this.message} - Date: ${new Date()}`
  }
  // Function to get the error details
  getErrorDetailsWithDateAndTime(): string {
    return `Error: ${this.name} - Status: ${this.status} - Message: ${this.message} - Date: ${new Date().toLocaleDateString()} - Time: ${new Date().toLocaleTimeString()}`
  }
  // Function to get the error details
  getErrorDetailsWithDateAndTimeAndTimeZone(): string {
    return `Error: ${this.name} - Status: ${this.status} - Message: ${this.message} - Date: ${new Date().toLocaleDateString()} - Time: ${new Date().toLocaleTimeString()} - Timezone: ${new Date().toLocaleTimeString('en-US', { timeZoneName: 'short' })}`
  }
}
