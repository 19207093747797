<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// PRIMEVUE
import { default as PInputGroup } from 'primevue/inputgroup'
import { default as PTextarea } from 'primevue/textarea'
import { default as PRadioButton } from 'primevue/radiobutton'
import { default as PInputGroupAddon } from 'primevue/inputgroupaddon'

// Custom components and types
import type { ITrueFalseAnswer } from '@/assets/types/learning/answers'

// VUE, VUE ROUTER, ETC...
import { type ModelRef, onMounted, type Ref, ref } from 'vue'
import { LearningEnums } from '@/assets/types/learning/enums'

const answers: ModelRef<ITrueFalseAnswer[]> = defineModel('answers', { required: true })
const correctAnswer: Ref<number | null> = ref(null)

onMounted(() => {
  answers.value.forEach((answer, i) => {
    if (answer.isCorrect) {
      correctAnswer.value = i
    }
  })
})

function updateAnswer(index: number) {
  answers.value.forEach((answer, i) => {
    answer.isCorrect = i === index
  })
}

onMounted(() => {
  if (answers.value.length === 0) {
    answers.value = [
      {
        label: 'Vrai',
        isCorrect: true,
        type: LearningEnums.QuestionType.TRUE_FALSE
      },
      {
        label: 'Faux',
        isCorrect: false,
        type: LearningEnums.QuestionType.TRUE_FALSE
      }
    ]
  }
})
</script>

<template>
  <div class="flex flex-column gap-1">
    <p-input-group v-for="(answer, i) in answers" :key="`answer_${i}`">
      <p-input-group-addon>
        <p-radio-button
          :value="i"
          v-model="correctAnswer"
          name="answer"
          @update:model-value="() => updateAnswer(i)"
        />
        <label v-if="i === 0" class="ml-2 w-3rem">Vrai</label>
        <label v-else class="ml-2 w-3rem">Faux</label>
      </p-input-group-addon>
      <p-textarea
        v-model="answer.label"
        style="min-height: 50px; height: 50px"
        placeholder="Texte de la réponse"
      />
    </p-input-group>
  </div>
</template>
