import type {
  ISchoolClassroom,
  ISchoolClassroomGroupUserLoginHistory,
  ISchoolClassroomScoreDiagnostic,
  ISchoolClassroomScoreModule
} from '@/assets/types/school/ISchoolClassroom'
import API from '@/services/API'
import type { AxiosResponse } from 'axios'
import {
  SchoolClassroomGroupUserLoginHistoryDto,
  SchoolClassroomResponseDto,
  SchoolClassroomScoreDiagnosticDto,
  SchoolClassroomScoreModuleDto
} from '@/assets/DTO/school/school.classroom.response.dto'
import { NabooError } from '@/assets/classes/Error'

export const schoolClassroom = {
  /**
   * Get a classroom by its ID
   * @param schoolId
   * @param schoolClassroomId
   */
  async getSchoolClassroomById(
    schoolId: number,
    schoolClassroomId: number
  ): Promise<SchoolClassroomResponseDto> {
    try {
      const response: AxiosResponse<ISchoolClassroom> = await API().get(
        `admin/schools/${schoolId}/classrooms/${schoolClassroomId}`
      )
      return Promise.resolve(new SchoolClassroomResponseDto(response.data))
    } catch (error) {
      if (error instanceof NabooError) {
        error.setStack('API/schoolClassroom.ts - getSchoolClassroomById')
        error.setMessage('Une erreur est survenue lors de la récupération de la classe.')
      }
      return Promise.reject(error)
    }
  },

  /**
   * Get the login history of a classroom
   * @param schoolId
   * @param schoolClassroomId
   */
  async getSchoolClassroomLoginHistory(
    schoolId: number,
    schoolClassroomId: number
  ): Promise<SchoolClassroomGroupUserLoginHistoryDto> {
    try {
      const response: AxiosResponse<ISchoolClassroomGroupUserLoginHistory> = await API().get(
        `admin/schools/${schoolId}/classrooms/${schoolClassroomId}/login-history`
      )
      return Promise.resolve(new SchoolClassroomGroupUserLoginHistoryDto(response.data))
    } catch (error) {
      if (error instanceof NabooError) {
        error.setStack('API/schoolClassroom.ts - getSchoolClassroomLoginHistory')
        error.setMessage(
          "Une erreur est survenue lors de la récupération de l'historique de connexion."
        )
      }
      return Promise.reject(error)
    }
  },

  /**
   * Get the diagnostic of a classroom score for a leaning course
   * @param schoolId
   * @param schoolClassroomId
   * @param learningCourseId
   */
  async getSchoolClassroomScoreDiagnostic(
    schoolId: number,
    schoolClassroomId: number,
    learningCourseId: number
  ): Promise<SchoolClassroomScoreDiagnosticDto[]> {
    try {
      const response: AxiosResponse<ISchoolClassroomScoreDiagnostic[]> = await API().get(
        `admin/schools/${schoolId}/classrooms/${schoolClassroomId}/courses/${learningCourseId}/diagnostic`
      )
      return Promise.resolve(
        response.data.map(
          (school: ISchoolClassroomScoreDiagnostic) => new SchoolClassroomScoreDiagnosticDto(school)
        )
      )
    } catch (error) {
      if (error instanceof NabooError) {
        error.setStack('API/schoolClassroom.ts - getSchoolClassroomScoreDiagnostic')
        error.setMessage('Une erreur est survenue lors de la récupération du diagnostic de score.')
      }
      return Promise.reject(error)
    }
  },

  /**
   * Get the score by task ID of a classroom
   * @param schoolId
   * @param schoolClassroom
   * @param taskId
   */
  async getSchoolClassroomScoreModule(
    schoolId: number,
    schoolClassroom: number,
    taskId: number
  ): Promise<SchoolClassroomScoreModuleDto[]> {
    try {
      const response: AxiosResponse<ISchoolClassroomScoreModule[]> = await API().get(
        `admin/schools/${schoolId}/classrooms/${schoolClassroom}/tasks/${taskId}/module`
      )
      return Promise.resolve(
        response.data.map(
          (school: ISchoolClassroomScoreModule) => new SchoolClassroomScoreModuleDto(school)
        )
      )
    } catch (error) {
      if (error instanceof NabooError) {
        error.setStack('API/schoolClassroom.ts - getSchoolClassroomScoreModule')
        error.setMessage('Une erreur est survenue lors de la récupération du score par module.')
      }
      return Promise.reject(error)
    }
  }
}
