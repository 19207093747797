<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// PRIMEVUE
import { default as PInputGroup } from 'primevue/inputgroup'
import { default as PTextarea } from 'primevue/textarea'
import { default as PRadioButton } from 'primevue/radiobutton'
import { default as PInputGroupAddon } from 'primevue/inputgroupaddon'
import { default as PButton } from 'primevue/button'

// Custom components and types
import type { IUCQAnswer } from '@/assets/types/learning/answers'

// VUE, VUE ROUTER, ETC...
import { type ModelRef, onMounted, type Ref, ref } from 'vue'
import { LearningEnums } from '@/assets/types/learning/enums'

const answers: ModelRef<IUCQAnswer[]> = defineModel('answers', { required: true })
const correctAnswer: Ref<number | null> = ref(null)

onMounted(() => {
  answers.value.forEach((answer, i) => {
    if (answer.isCorrect) {
      correctAnswer.value = i
    }
  })
})

function addQuestion() {
  answers.value.push({
    label: '',
    isCorrect: answers.value.length === 0,
    type: LearningEnums.QuestionType.SINGLE_CHOICE
  })
}

function updateAnswer(index: number) {
  answers.value.forEach((answer, i) => {
    answer.isCorrect = i === index
  })
}

function deleteAnswer(index: number) {
  answers.value.splice(index, 1)
}
</script>

<template>
  <div class="flex flex-column gap-1">
    <p-input-group v-for="(answer, i) in answers" :key="`answer_${i}_${answer.id}`">
      <p-input-group-addon>
        <p-radio-button
          :value="i"
          v-model="correctAnswer"
          name="answer"
          @update:model-value="() => updateAnswer(i)"
        />
      </p-input-group-addon>
      <p-textarea
        v-model="answer.label"
        style="min-height: 50px; height: 50px"
        placeholder="Texte de la réponse"
      />
      <p-button @click="deleteAnswer(i)" icon="pi pi-trash" text severity="secondary" />
    </p-input-group>
  </div>

  <p-button
    label="Ajouter une réponse"
    icon="pi pi-plus"
    class="mt-3"
    size="small"
    outlined
    @click="addQuestion"
  />
</template>
