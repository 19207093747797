<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// PrimeVue
import { default as PButton } from 'primevue/button'
import { default as PTag } from 'primevue/tag'

// Vue, Vue Router etc
import type { RouteLocationRaw } from 'vue-router'

// Custom components and types
import { LearningEnums } from '@/assets/types/learning/enums'
import { computed } from 'vue'

const props = defineProps<{
  status?: LearningEnums.Status
  primaryButton: {
    label: string
    onClick: () => void
    icon?: string
  }
  secondaryButton?: {
    label: string
    onClick: () => void
    icon?: string
  }
  backTo?: {
    label: string
    route: RouteLocationRaw
  }
  title: string
  subtitle?: string
}>()

const tagColor = computed(() => {
  switch (props.status) {
    case LearningEnums.Status.DRAFT:
      return 'secondary'
    case LearningEnums.Status.ACTIVE:
      return 'success'
    case LearningEnums.Status.ARCHIVED:
      return 'danger'
    default:
      return 'warning'
  }
})
</script>

<template>
  <div class="w-full grid grid-nogutter p-5" ref="Header">
    <div class="col-8 justify-content-start flex flex-column gap-2">
      <router-link v-if="backTo" :to="backTo.route" class='text-color-secondary no-underline'>&lt; {{ backTo.label }}</router-link>
      <h1 class="text-left">{{ title }}</h1>
    </div>

    <div class="col-4 flex gap-3 justify-content-end align-items-center h-full">
      <div class="flex gap-2 align-items-center" v-if="status">
        <p class="font-semibold text-color-secondary">État :</p>
        <p-tag :value="LearningEnums.statusLabel(status)" :severity="tagColor" />
      </div>
      <p-button
        v-if="secondaryButton"
        :label="secondaryButton.label"
        severity="secondary"
        size="small"
        :icon="secondaryButton.icon"
        outlined
        class="white-space-nowrap"
        @click="secondaryButton.onClick"
      />
      <p-button
        :label="primaryButton.label"
        :icon="primaryButton.icon"
        severity="primary"
        size="small"
        class="white-space-nowrap"
        @click="primaryButton.onClick"
      />
    </div>
  </div>
</template>
