import type { Auth0Plugin } from '@auth0/auth0-vue'
import { createAuth0 } from '@auth0/auth0-vue'
import { CustomAuth0SessionCache } from '@/assets/classes/CustomAuth0SessionCache'

const auth0Client: Auth0Plugin = createAuth0({
  domain: __AUTH0_DOMAIN__,
  clientId: __AUTH0_CLIENT_ID__,
  useRefreshTokens: true,
  cache: new CustomAuth0SessionCache(),
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: __AUTH0_AUDIENCE__
  }
})

export default auth0Client
