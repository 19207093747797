import type { ISchool } from '@/assets/types/school/ISchool'
import type { ISchoolUser } from '@/assets/types/school/ISchoolUser'
import { SchoolUserResponseDto } from '@/assets/DTO/school/school.user.response.dto'
import { SchoolClassroomResponseDto } from '@/assets/DTO/school/school.classroom.response.dto'
import { SchoolMonitoringGroupResponseDto } from '@/assets/DTO/school/school.monitoring.group.response.dto'

export class SchoolResponseDto {
  id: number
  codeUai: string
  name: string
  city: string
  zipCode: string
  codeInternal: string
  classrooms: SchoolClassroomResponseDto[] = []
  monitoringGroups: SchoolMonitoringGroupResponseDto[] = []
  users: SchoolUserResponseDto[] = []

  constructor(input: ISchool) {
    this.id = input.id
    this.codeUai = input.codeUai
    this.name = input.name
    this.city = input.city
    this.zipCode = input.zipCode
    this.codeInternal = input.codeInternal
    this.classrooms = input.classrooms.map((classroom) => new SchoolClassroomResponseDto(classroom))
    this.monitoringGroups = input.monitoringGroups.map(
      (group) => new SchoolMonitoringGroupResponseDto(group)
    )
    this.users = input.users.map((user) => {
      return new SchoolUserResponseDto(user)
    })
  }

  getTeachers(): ISchoolUser[] {
    return this.users.filter((user) => user.isTeacher())
  }

  getStudents(): ISchoolUser[] {
    return this.users.filter((user) => user.isStudent())
  }
}
