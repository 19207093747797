<script setup lang="ts">
import { computed, type ModelRef } from 'vue'
import type { IQuestion } from '@/assets/types/learning/QuizQuestion'
import { default as PButton } from 'primevue/button'
import { default as PTextarea } from 'primevue/textarea'
import { default as PInlineMessage } from 'primevue/inlinemessage'
import UCQAnswer from '@/views/quiz/blocks/answers/UCQAnswer.vue'
import MCQAnswer from '@/views/quiz/blocks/answers/MCQAnswer.vue'
import TrueFalseAnswer from '@/views/quiz/blocks/answers/TrueFalseAnswer.vue'
import SortingAnswer from '@/views/quiz/blocks/answers/SequencingAnswer.vue'
import AssociationAnswer from '@/views/quiz/blocks/answers/MatchingAnswer.vue'
import { LearningEnums } from '@/assets/types/learning/enums'

const questionModel: ModelRef<IQuestion> = defineModel('question', { required: true })

defineProps<{
  validateUpdate: () => void
}>()

const componentAnswer = computed(() => {
  switch (questionModel.value.type) {
    case LearningEnums.QuestionType.SINGLE_CHOICE:
      return UCQAnswer
    case LearningEnums.QuestionType.MULTIPLE_CHOICE:
      return MCQAnswer
    case LearningEnums.QuestionType.TRUE_FALSE:
      return TrueFalseAnswer
    case LearningEnums.QuestionType.SEQUENCING:
      return SortingAnswer
    case LearningEnums.QuestionType.MATCHING:
      return AssociationAnswer
    default:
      return UCQAnswer
  }
})


</script>

<template>
  <div class="grid grid-nogutter">
    <div class="col-12 flex align-items-center justify-content-between">
      <h4 class="text-color block font-bold">Paramètre de la question {{ questionModel.order }}</h4>

      <div>
        <p-button severity="secondary" label="Annuler" @click="$emit('cancel')" />
        <p-button label="Valider" class="ml-3" @click="validateUpdate()" />
      </div>
    </div>

    <div class="col-12 mt-5">
      <h5>Les réponses</h5>
      <p-inline-message severity="info" v-if="questionModel.answers.length === 0">
        Il n'y pas encore de réponses pour cette question. Cliquer sur le bouton pour ajouter une
        réponse.
      </p-inline-message>
      <component :is="componentAnswer" v-model:answers="questionModel.answers"  />
    </div>

    <div class="col-12 mt-5">
      <h5>Feedbacks</h5>

      <div class="flex flex-column gap-2">
        <p>Réponse juste</p>
        <p-textarea class="w-full" v-model="questionModel.feedbacks.correct" />
      </div>

      <div class="flex flex-column gap-2 mt-3">
        <p>Réponse fausse</p>
        <p-textarea class="w-full" v-model="questionModel.feedbacks.wrong" />
      </div>
    </div>
  </div>
</template>
