<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// Vue, router
import { computed, ref } from 'vue'
import { type RouteLocationRaw, useRoute } from 'vue-router'

// PrimeVue
import { default as PButton } from 'primevue/button'

// Custom components and types
import type { ILearningModule } from '@/assets/types/learning/LearningModuleTypes'
import type { ILearningGrain } from '@/assets/types/learning/LearningGrainTypes'
import { LearningEnums } from '@/assets/types/learning/enums'
import type { ILearningTask } from '@/assets/types/Referential'
import type { ILearningCourse } from '@/assets/types/learning/LearningCourses'

// Utils
const route = useRoute()

// Data, refs
const Header = ref<HTMLElement | null>(null)

// Props
const props = defineProps<{
  learningModule?: ILearningModule
  learningGrain?: ILearningGrain
  backTo: RouteLocationRaw
  learningCourse?: ILearningCourse
  task?: ILearningTask
  isQuizReady: boolean
}>()

defineExpose({
  Header
})

// Computed
const quizTypeText = computed(() => {
  switch (route.query.quizType) {
    case LearningEnums.QuizType.PRE:
      return 'de début de grain'
    case LearningEnums.QuizType.POST:
      return 'de fin de grain'
    case LearningEnums.QuizType.DIAG:
      return 'de diagnostic'
    default:
      return ''
  }
})

const backToText = computed(() => {
  if (route.query.quizType === (LearningEnums.QuizType.PRE || LearningEnums.QuizType.POST)) {
    return '< Retour au module'
  } else if (route.query.quizType === LearningEnums.QuizType.DIAG) {
    return `< Revenir à l'édition du parcours "${props.learningCourse?.title}"`
  } else {
    return '< Retour au module'
  }
})

const titleText = computed(() => {
  if (props.learningModule?.id) {
    return `MODULE : ${props.learningModule.id}: ${props.learningModule.title}`
  } else {
    return `Tâche : ${props.task?.title}`
  }
})

const quizTitle = computed(() => {
  if (props.learningGrain?.title) {
    return `Quiz ${quizTypeText.value} : ${props.learningGrain.title}`
  } else {
    return `Quiz ${quizTypeText.value}`
  }
})
</script>

<template>
  <div class="col-12 grid grid-nogutter p-5 py-3 border-bottom-1 border-300" ref="Header">
    <div class="col justify-content-start flex flex-column gap-2">
      <router-link
        :to="backTo"
        class="flex align-items-center gap-2 w-full text-color-secondary no-underline"
        >{{ backToText }}
      </router-link>
      <h4 class="text-left">
        {{ titleText }}
      </h4>
      <h3 class="uppercase text-left">
        {{ quizTitle }}
      </h3>
    </div>

    <div class="col flex gap-3 justify-content-end align-items-center h-full">
      <p-button
        label="Enregistrer le quiz et quitter"
        severity="primary"
        size="small"
        class="white-space-nowrap"
        @click="$emit('save')"
      />
      <p-button
        label="Publier le quiz"
        :disabled="isQuizReady"
        severity="primary"
        size="small"
        class="white-space-nowrap"
        @click="$emit('publish')"
      />
    </div>
  </div>
</template>
