import type { ISchoolUser } from '@/assets/types/school/ISchoolUser'
import { Role } from '@/assets/types/UserTypes'

export class SchoolUserResponseDto {
  identifier: number
  firstname: string
  lastname: string
  role: Role.Values[]
  birthdate: Date | null

  constructor(input: ISchoolUser) {
    this.identifier = input.identifier
    this.firstname = input.firstname
    this.lastname = input.lastname
    this.role = input.role
    this.birthdate = input.birthdate
  }

  isStudent(): boolean {
    return this.role.includes(Role.Values.STUDENT)
  }

  isTeacher(): boolean {
    return this.role.includes(Role.Values.TEACHER)
  }
}
