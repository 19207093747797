import axios, { AxiosError } from 'axios'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { errorHandler } from '@/services/errorHandler'

const config = {
  baseURL: __BASE_URL_API__ + '/api/',
  timeout: 10000,
  withCredentials: true,
  headers: {}
}

export default (secure = true) => {
  const axios_instance = axios.create(config)
  const { getAccessTokenSilently } = useAuthStore()
  const { isAuthenticated } = storeToRefs(useAuthStore())

  axios_instance.interceptors.request.use(
    async function (config) {
      if (secure && isAuthenticated.value) {
        // Set the jwt token in the header
        const token = await getAccessTokenSilently()
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    async function (error) {
      if (!axios.isCancel(error)) return error
    }
  )

  axios_instance.interceptors.response.use(
    async function (response) {
      return response
    },
    async function (error: AxiosError) {
      return Promise.reject(errorHandler(error))
    }
  )
  return axios_instance
}
