import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { useLocalStorage } from '@vueuse/core'
import type { RemovableRef } from '@vueuse/core/'
import type { IUser } from '@/assets/types/UserTypes'
import { useAuth0 } from '@auth0/auth0-vue'

interface IPreferences {
  menuOpened: boolean
}

export const useProfileStore = defineStore('Profile', () => {
  // STATE
  const me: RemovableRef<IUser> = ref(useLocalStorage('me', {} as IUser))
  const preferences: RemovableRef<IPreferences> = useLocalStorage('preferences', {
    menuOpened: true
  })
  const { user: userAuth0 } = useAuth0()

  // ACTIONS
  async function getMe() {
    try {
      const res = await nabooApi.getMe()
      me.value = res.data as IUser
    } catch (error: any) {
      me.value = {} as IUser
    }
  }

  function toggleMenuOpen() {
    preferences.value.menuOpened = !preferences.value.menuOpened
  }

  // GETTERS (COMPUTED)
  const getFirstNameInitial = computed(() => {
    if (!me?.value?.firstname && !userAuth0.value?.given_name) return ''
    return me.value.firstname?.charAt(0) ?? userAuth0.value?.given_name?.charAt(0)
  })

  const getFullName = computed(() => {
    if (!me?.value?.firstname || !me?.value?.lastname || !userAuth0?.value?.name) return ''
    return `${me.value.firstname} ${me.value.lastname}` ?? `${userAuth0.value.name}`
  })

  const getPicture = computed(() => {
    return me.value.picture ?? userAuth0.value.picture
  })

  const isMenuOpened = computed(() => preferences.value.menuOpened)

  return { getMe, toggleMenuOpen, me, getFirstNameInitial, getFullName, getPicture, isMenuOpened }
})
