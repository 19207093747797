<script lang="ts" setup>
import { onMounted } from 'vue'
import { useReferentialStore } from '@/stores/referential'
const { fetchAllAxises } = useReferentialStore()

onMounted(async () => {
  await fetchAllAxises()
})
</script>

<template>
  <router-view />
</template>
