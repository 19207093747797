<script setup lang="ts">
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'

import { default as PToast } from 'primevue/toast'
import { default as PConfirmDialog } from 'primevue/confirmdialog'
import { default as PTerminal } from 'primevue/terminal'
import TerminalService from 'primevue/terminalservice'

import { useAuthStore } from '@/stores/auth'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useMagicKeys } from '@vueuse/core'

import { useCommandHandler } from '@/components/terminal/useCommandHandler'

const { initLogin, loginWithRedirect } = useAuthStore()
const { isAuthenticated } = storeToRefs(useAuthStore())

// Get the router
const router = useRouter()

// Cheats to get current version of the app
const showTerminal = ref(false)
const keys = useMagicKeys()
const versionKeys = keys['Ctrl+Alt+w']
watch(versionKeys, async (pressed) => {
  if (pressed) {
    showTerminal.value = !showTerminal.value
  }
})

onMounted(async () => {
  await router.isReady()
  if (isAuthenticated.value) {
    await initLogin()
    TerminalService.on('command', useCommandHandler)
  } else if (!router.currentRoute.value.query.code && !router.currentRoute.value.query.state) {
    await loginWithRedirect()
  }
})

onBeforeUnmount(() => {
  TerminalService.off('command', useCommandHandler)
})
</script>

<template>
  <default-template :with-header="$route.meta.withHeader" :with-sidebar="$route.meta.withSidebar">
    <RouterView />
    <p-toast />
    <p-confirm-dialog></p-confirm-dialog>
  </default-template>

  <p-terminal
    v-if="showTerminal"
    class="naboo-terminal"
    prompt="naboo $"
    aria-label="Naboo-BO Terminal Service"
  />
</template>

<style lang="scss" scoped>
.p-terminal {
  background-color: #000;
  color: #fff;
  font-family: monospace;
  font-size: 0.75rem;

  .p-terminal-response {
    color: yellow;
  }
}

.naboo-terminal {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 10rem;
  max-height: 50%;
  overflow-y: auto;
  padding: 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  transition: height 0.3s;
}
</style>
