<script setup lang="ts">
import { ref } from 'vue'
import DashboardBaseBlock from '@/views/dashboard/blocks/DashboardBaseBlock.vue'

const showStudentsDetail = ref(false)
</script>

<template>
  <div class="flex flex-column grid col-12">
    <dashboard-base-block title="Diagnostic">
      <p>Le diagnostic est un quiz réalisé à chaque début de nouveau parcours.</p>
      <p>
        Le diagnostic est découpé en thématiques, qui sont ensuite abordées indépendamment dans
        chaque module.
      </p>
      // GRAPHIQUE //

      <a class="cursor-pointer" @click="showStudentsDetail = !showStudentsDetail"
        >Afficher le détail par élève
        <i class="pi" :class="showStudentsDetail ? 'pi-chevron-up' : 'pi-chevron-down'" />
      </a>

      <template v-if="showStudentsDetail">
        <p>Tableau des élèves</p>
      </template>
    </dashboard-base-block>
  </div>
</template>
