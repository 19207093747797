import { SchoolUserResponseDto } from '@/assets/DTO/school/school.user.response.dto'
import type { ISchoolMonitoringGroup } from '@/assets/types/school/ISchoolMonitoringGroup'
import { SchoolYearResponseDto } from '@/assets/DTO/school/school.year.response.dto'

export class SchoolMonitoringGroupResponseDto {
  id: number
  name: string
  schoolId: number
  schoolYear: SchoolYearResponseDto
  teacher: SchoolUserResponseDto
  students: SchoolUserResponseDto[]

  constructor(input: ISchoolMonitoringGroup) {
    this.id = input.id
    this.name = input.name
    this.schoolId = input.schoolId
    this.schoolYear = new SchoolYearResponseDto(input.schoolYear)
    this.teacher = new SchoolUserResponseDto(input.teacher)
    this.students = input.students.map((student) => new SchoolUserResponseDto(student))
  }
}
