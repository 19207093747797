import API from '@/services/API'
import * as Sentry from '@sentry/vue'

import type { ILearningModuleInput } from '@/assets/types/learning/LearningModuleTypes'
import { NabooError } from '@/assets/classes/Error'
import { LearningModuleResponseDto } from '@/assets/DTO/learning/learningModule.response.dto'
import { LearningEnums } from '@/assets/types/learning/enums'
import qs from 'qs'

const getModuleOptions = {
  withGrains: true,
  withSupports: true,
  withTasks: true,
  status: [LearningEnums.Status.DRAFT, LearningEnums.Status.ACTIVE]
}

export const learningModules = {
  async createModule(module: ILearningModuleInput, learningCourseId: number) {
    try {
      const response = await API().post('learning/courses/' + learningCourseId + '/modules', module)
      const learningModule = new LearningModuleResponseDto(response.data)
      return Promise.resolve(learningModule)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningModules.ts - createModule')
        error.setMessage('Une erreur est survenue lors de la création du module.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la création du module.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  },

  async getModuleById(moduleId: number, learningCourseId: number) {
    try {
      const response = await API().get(`learning/courses/${learningCourseId}/modules/${moduleId}`, { params: getModuleOptions, paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }) })
      const learningModule: LearningModuleResponseDto = new LearningModuleResponseDto(response.data)
      return Promise.resolve(learningModule)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningModules.ts - getModuleById')
        error.setMessage('Une erreur est survenue lors de la récupération du module.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération du module.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  },

  async updateModule(module: ILearningModuleInput, learningModuleId: number, learningCourseId: number) {
    try {
      const response = await API().put(`learning/courses/${learningCourseId}/modules/${learningModuleId}`, module)
      const learningModule = new LearningModuleResponseDto(response.data)
      return Promise.resolve(learningModule)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningModules.ts - updateModule')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la mise à jour du module.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  },

  async updateModuleStatus(
    status: LearningEnums.Status,
    moduleId: number,
    learningCourseId: number
  ) {
    try {
      const response = await API().put(
        `learning/courses/${learningCourseId}/modules/${moduleId}/status`,
        { status }
      )
      const learningModule = new LearningModuleResponseDto(response.data)

      return Promise.resolve(learningModule)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningModules.ts - updateStatus')
        error.setMessage('Une erreur est survenue lors de la mise à jour du statut du module.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la mise à jour du statut du module.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  }
}
