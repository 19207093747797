import type { AxiosError } from 'axios'
import { NabooError } from '@/assets/classes/Error'

export const errorHandler = (axiosError: AxiosError) => {
  const status = axiosError.response?.status || 0
  const code = axiosError.code || 'ERR_UNKNOWN'

  const error = new NabooError({
    message: 'Une erreur est survenue',
    status: status,
    name: 'Erreur',
    code: code,
    error: axiosError
  })

  // GENERAL ERROR
  if (axiosError.code === 'ECONNABORTED') {
    error.setMessage('Le serveur ne répond pas, veuillez patienter.')
    return error
  } else if (axiosError.code === 'ERR_CANCELED' && axiosError.config?.url === 'xapi/statements') {
    error.setMessage('Veuillez ignorer ce message.')
    return error
  }

  error.setMessage('Une erreur est survenue, veuillez réessayer plus tard.')
  return error
}
