import type {
  Degree,
  ISchoolClassroom,
  ISchoolClassroomGroupUserLoginHistory,
  ISchoolClassroomScoreDiagnostic,
  ISchoolClassroomScoreDiagnosticTask,
  ISchoolClassroomScoreModule,
  ISchoolClassroomScoreModuleLearningGrain,
  ISchoolClassroomUserLoginHistory
} from '@/assets/types/school/ISchoolClassroom'
import { SchoolUserResponseDto } from '@/assets/DTO/school/school.user.response.dto'
import { SchoolYearResponseDto } from '@/assets/DTO/school/school.year.response.dto'

/**
 * SchoolClassroomResponseDto
 */
export class SchoolClassroomResponseDto {
  id: number
  name: string
  degree: Degree.Values
  schoolId: number
  schoolYear: SchoolYearResponseDto
  teachers: SchoolUserResponseDto[] = []
  students: SchoolUserResponseDto[] = []

  constructor(input: ISchoolClassroom) {
    this.id = input.id
    this.name = input.name
    this.degree = input.degree
    this.schoolId = input.schoolId
    this.schoolYear = new SchoolYearResponseDto(input.schoolYear)
    this.teachers = input.teachers?.map((teacher) => new SchoolUserResponseDto(teacher))
    this.students = input.students?.map((student) => new SchoolUserResponseDto(student))
  }
}

/**
 * SchoolClassroomUserLoginHistoryDto
 */
export class SchoolClassroomUserLoginHistoryDto {
  identifier: string
  firstname: string
  lastname: string
  lastLogin: Date | null

  constructor(input: ISchoolClassroomUserLoginHistory) {
    this.identifier = input.identifier
    this.firstname = input.firstname
    this.lastname = input.lastname
    this.lastLogin = input.lastLogin
  }

  get fullname(): string {
    return `${this.firstname} ${this.lastname}`
  }
}

/**
 * SchoolClassroomGroupUserLoginHistoryDto
 */
export class SchoolClassroomGroupUserLoginHistoryDto {
  lessThan7Days: SchoolClassroomUserLoginHistoryDto[] = []
  between7And14Days: SchoolClassroomUserLoginHistoryDto[] = []
  moreThan14Days: SchoolClassroomUserLoginHistoryDto[] = []

  constructor(input: ISchoolClassroomGroupUserLoginHistory) {
    this.lessThan7Days = input.lessThan7Days.map(
      (user) => new SchoolClassroomUserLoginHistoryDto(user)
    )
    this.between7And14Days = input.between7And14Days.map(
      (user) => new SchoolClassroomUserLoginHistoryDto(user)
    )
    this.moreThan14Days = input.moreThan14Days.map(
      (user) => new SchoolClassroomUserLoginHistoryDto(user)
    )
  }
}

/**
 * SchoolClassroomScoreDiagnosticTaskDto
 */
export class SchoolClassroomScoreDiagnosticTaskDto {
  taskId: number
  taskTitle: string
  taskOrder: string
  diagScorePercentRounded: number | null
  diagEvalRangeName: string | null

  constructor(input: ISchoolClassroomScoreDiagnosticTask) {
    this.taskId = input.taskId
    this.taskTitle = input.taskTitle
    this.taskOrder = input.taskOrder
    this.diagScorePercentRounded = input.diagScorePercentRounded
    this.diagEvalRangeName = input.diagEvalRangeName
  }
}

/**
 * SchoolClassroomScoreDiagnosticDto
 */
export class SchoolClassroomScoreDiagnosticDto {
  identifier: string
  firstname: string
  lastname: string
  teacherFirstname: string | null
  teacherLastname: string | null
  tasks: SchoolClassroomScoreDiagnosticTaskDto[]

  constructor(input: ISchoolClassroomScoreDiagnostic) {
    this.identifier = input.identifier
    this.firstname = input.firstname
    this.lastname = input.lastname
    this.teacherFirstname = input.teacherFirstname
    this.teacherLastname = input.teacherLastname
    this.tasks = input.tasks.map((task) => new SchoolClassroomScoreDiagnosticTaskDto(task))
  }
}

/**
 * SchoolClassroomScoreModuleLearningGrainDto
 */
export class SchoolClassroomScoreModuleLearningGrainDto {
  learningGrainId: number
  learningGrainTitle: string
  learningGrainOrder: string
  postQuizEvalRangeName: number | null

  constructor(input: ISchoolClassroomScoreModuleLearningGrain) {
    this.learningGrainId = input.learningGrainId
    this.learningGrainTitle = input.learningGrainTitle
    this.learningGrainOrder = input.learningGrainOrder
    this.postQuizEvalRangeName = input.postQuizEvalRangeName
  }
}

/**
 * SchoolClassroomScoreModuleDto
 */
export class SchoolClassroomScoreModuleDto {
  identifier: string
  firstname: string
  lastname: string
  teacherFirstname: string | null
  teacherLastname: string | null
  taskId: number
  taskTitle: string
  taskOrder: string
  diagScorePercentRounded: number | null
  diagEvalRangeName: string | null
  moduleScorePercentRounded: number | null
  moduleEvalRangeName: string | null
  diagnosticModuleVariation: number | null
  learningGrains: SchoolClassroomScoreModuleLearningGrainDto[]

  constructor(input: ISchoolClassroomScoreModule) {
    this.identifier = input.identifier
    this.firstname = input.firstname
    this.lastname = input.lastname
    this.teacherFirstname = input.teacherFirstname
    this.teacherLastname = input.teacherLastname
    this.taskId = input.taskId
    this.taskTitle = input.taskTitle
    this.taskOrder = input.taskOrder
    this.diagScorePercentRounded = input.diagScorePercentRounded
    this.diagEvalRangeName = input.diagEvalRangeName
    this.moduleScorePercentRounded = input.moduleScorePercentRounded
    this.moduleEvalRangeName = input.moduleEvalRangeName
    this.diagnosticModuleVariation = input.diagnosticModuleVariation
    this.learningGrains = input.learningGrains.map(
      (learningGrain) => new SchoolClassroomScoreModuleLearningGrainDto(learningGrain)
    )
  }
}
