<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// PRIMEVUE
import { default as PButton } from 'primevue/button'
import { default as PButtonGroup } from 'primevue/buttongroup'
import { default as PDropdown } from 'primevue/dropdown'
import { default as PTextarea } from 'primevue/textarea'

// Custom components and types
import type { IQuestion } from '@/assets/types/learning/QuizQuestion'
import { LearningEnums } from '@/assets/types/learning/enums'

// VUE
import { computed, type ModelRef } from 'vue'
import { generateDataOptionsFromEnum } from '@/assets/utils/dataOptions'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const emit = defineEmits(['edit', 'duplicate', 'delete'])
const question: ModelRef<IQuestion> = defineModel('question', { required: true })

defineProps<{
  isEditing: boolean
}>()

const questiontypeOptions = computed(() => {
  return generateDataOptionsFromEnum(LearningEnums.QuestionType, (a) =>
    LearningEnums.questionTypeLabel(a as LearningEnums.QuestionType)
  )
})

function handleUpdate() {
  question.value.answers = []
}

function handleEdit() {
  emit('edit', question.value)
}

</script>

<template>
  <div
    class="question p-3 border-1 border-round border-300 border-dashed w-full col-12 h-fit cursor-pointer"
    :class="isEditing ? 'bg-blue-50' : 'bg-white'"
    @click="handleEdit"
  >
    <div class="grid w-full">
      <div class="col-6 flex gap-2 align-items-center">
        <FontAwesomeIcon icon="grip-vertical" class="handle cursor-move" />
        <p
          class="font-bold"
        >
          Question {{ question.order }}
        </p>
      </div>
      <div class="col-6 flex justify-content-end">
        <p-button-group>
          <p-button
            icon="pi pi-pencil"
            text
            size="small"
            severity="secondary"
            @click.stop="() => $emit('edit', question)"
          />
          <p-button
            icon="pi pi-copy"
            text
            size="small"
            severity="secondary"
            @click.stop="() => $emit('duplicate', question)"
          />
          <p-button
            icon="pi pi-trash"
            text
            size="small"
            severity="secondary"
            @click.stop="() => $emit('delete')"
          />
        </p-button-group>
      </div>

      <div class="col-12">
        <p class="font-semibold">Type de question</p>

        <p-dropdown
          v-if="isEditing"
          class="w-full"
          :options="questiontypeOptions"
          v-model="question.type"
          option-label="label"
          option-value="value"
          @update:model-value="() => handleUpdate()"
          @click.stop
        />
        <p v-else class="text-color-secondary">
          {{ LearningEnums.questionTypeLabel(question.type) }}
        </p>
      </div>

      <div class="col-12">
        <p class="font-semibold">Intitulé de la question</p>
        <p-textarea v-model="question.label" v-if="isEditing" class="w-full" @click.stop />
        <p class="text-color-secondary " v-else>{{ question.label }}</p>
      </div>
    </div>
  </div>
</template>
