<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// PRIMEVUE
import { default as PInputGroup } from 'primevue/inputgroup'
import { default as PTextarea } from 'primevue/textarea'
import { default as PInputGroupAddon } from 'primevue/inputgroupaddon'
import { default as PButton } from 'primevue/button'
import draggable from 'vuedraggable'

// Custom components and types
import type { ISequencingAnswer } from '@/assets/types/learning/answers'

// VUE, VUE ROUTER, ETC...
import { type ModelRef } from 'vue'
import { LearningEnums } from '@/assets/types/learning/enums'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const answers: ModelRef<ISequencingAnswer[]> = defineModel('answers', { required: true })

function addQuestion() {
  answers.value.push({
    data: {
      type: 'TEXT',
      value: ''
    },
    order: answers.value.length + 1,
    type: LearningEnums.QuestionType.SEQUENCING
  })
}

const dragOptions = {
  animation: 150,
  group: 'group1',
  disabled: false,
  ghostClass: 'ghost'
}

function deleteAnswer(index: number) {
  answers.value.splice(index, 1)
  updateAnswers()
}

function updateAnswers() {
  answers.value.forEach((answer, i) => {
    answer.order = i + 1
  })
}
</script>

<template>

  <div class="flex flex-column gap-1">
    <draggable
      v-model="answers"
      handle=".handle"
      v-bind="dragOptions"
      item-key="order"
      :component-data="{ tag: 'div', name: 'flip-list', type: 'transition' }"
      class="list-group flex flex-column gap-1"
      @end="updateAnswers"
    >
      <template #item="{ element, index }: { element: ISequencingAnswer; index: number }">
        <div class="w-full flex align-items-center gap-2">
          <FontAwesomeIcon icon="grip-vertical" class="handle cursor-move" />
          <p-input-group>
            <p-input-group-addon>
              <label>{{ index + 1 }}</label>
            </p-input-group-addon>
            <p-textarea
              v-model="element.data.value"
              style="min-height: 50px; height: 50px"
              placeholder="Texte de la réponse"
            />
          </p-input-group>
          <p-button @click="deleteAnswer(index)" icon="pi pi-trash" text severity="secondary" />
        </div>
      </template>
    </draggable>
  </div>

  <p-button
    label="Ajouter une réponse"
    icon="pi pi-plus"
    class="mt-3"
    size="small"
    outlined
    @click="addQuestion"
  />
</template>

<style>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
