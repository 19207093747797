import API from '@/services/API'
import type { ICrewInput } from '@/assets/types/CrewsTypes'

export const crews = {
  async fetchAllCrews() {
    return API().get('admin/crews')
  },

  async fetchCrewById(crewId: number) {
    return API().get(`admin/crews/${crewId}`)
  },

  async createCrew(crew: ICrewInput) {
    return API().post('admin/crews', crew)
  },

  async updateCrew(crew: ICrewInput) {
    return API().put(`admin/crews/${crew.id}`, crew)
  },

  async deleteCrew(crewId: number) {
    return API().delete(`admin/crews/${crewId}`)
  }
}
