<script setup lang="ts">
/*
 *
 * Imports
 *
 * */

//PrimeVue
import { default as PInputText } from 'primevue/inputtext'
import { default as PButton } from 'primevue/button'
import { default as PTag } from 'primevue/tag'

//Vue, router
import { computed, type ModelRef, ref } from 'vue'

//Custom components and types
import type { ILearningModuleInput } from '@/assets/types/learning/LearningModuleTypes'
import type { IDomain } from '@/assets/types/Referential'
import { LearningEnums } from '@/assets/types/learning/enums'

//Utils
const modelModule: ModelRef<ILearningModuleInput> = defineModel('module', { required: true })

const props = defineProps<{
  fieldToUpdate: string | null
  addGrainToModule: () => void
  startUpdate: (field: string) => void
  cancelUpdate: () => void
  validateUpdate: (sendInput?: boolean) => void
  publishModule: () => void
  canPublish: boolean
  domain: IDomain
  learningCourseId: number
  isCreating: boolean
  learningCourseTitle: string
}>()

const Header = ref<HTMLElement | null>(null)

defineExpose({
  Header
})

const backToText = computed(() => {
  return `< Revenir au parcours "${props.learningCourseTitle}"`
})
</script>

<template>
  <div class="col-12 grid grid-nogutter p-5 py-3 border-bottom-1 border-300" ref="Header">
    <template v-if="fieldToUpdate === 'title'">
      <div class="col-12 flex gap-2">
        <p-input-text v-model="modelModule.title" class="flex-grow-1" />
        <p-button
          severity="secondary"
          @click="cancelUpdate"
          label="Annuler"
          data-testid="cancel-update"
        />
        <p-button
          @click="validateUpdate(false)"
          label="Valider"
          data-testid="validate-update-title"
        />
      </div>
    </template>
    <template v-else>
      <div class="col flex flex-column gap-2">
          <router-link
            :to="{ name: 'learning-course-update', params: { id: learningCourseId } }"
            class="flex align-items-center gap-2 w-full text-color-secondary no-underline"
          >{{ backToText }}
          </router-link>
        <div class="flex align-items-center">
          <h2 class="pr-2">
            {{ modelModule.title }}
            <p-button
              icon="pi pi-pencil"
              text
              severity="secondary"
              @click="startUpdate('title')"
              data-testid="start-update-title"
              class="p-1"
            />
          </h2>
        </div>
        <div class="flex align-items-center gap-2">
          <p class="text-color-secondary font-semibold">État :</p>

          <p-tag
            :severity="LearningEnums.statusColor(modelModule.status)"
            :value="LearningEnums.statusLabel(modelModule.status)"
          />
        </div>
      </div>

      <div class="align-items-center grid col gap-2 ml-auto flex-grow-0 flex-nowrap">
        <p-button
          label="Publier le module"
          class="white-space-nowrap"
          @click="publishModule()"
          :disabled="!canPublish"
          size="small"
        />

        <p-button
          v-if="!isCreating"
          label="Enregistrer comme brouillon"
          class="white-space-nowrap"
          @click="validateUpdate()"
          :disabled="!canPublish"
          size="small"
          outlined
        />
      </div>
    </template>
  </div>
</template>
