import type { ISchoolYear } from '@/assets/types/school/ISchoolYear'
import type { ISchoolUser } from '@/assets/types/school/ISchoolUser'

export interface ISchoolClassroom {
  id: number
  name: string
  degree: Degree.Values
  schoolId: number
  schoolYear: ISchoolYear
  teachers: ISchoolUser[]
  students: ISchoolUser[]
}

export namespace Degree {
  export enum Values {
    SECONDE = 'SECONDE',
    PREMIERE = 'PREMIERE',
    TERMINALE = 'TERMINALE'
  }

  export function label(degree: Values) {
    switch (degree) {
      case Values.SECONDE:
        return 'Seconde'
      case Values.PREMIERE:
        return 'Première'
      case Values.TERMINALE:
        return 'Terminale'
    }
  }
}

export interface ISchoolClassroomUserLoginHistory {
  identifier: string
  firstname: string
  lastname: string
  lastLogin: Date | null
}

export interface ISchoolClassroomGroupUserLoginHistory {
  lessThan7Days: ISchoolClassroomUserLoginHistory[]
  between7And14Days: ISchoolClassroomUserLoginHistory[]
  moreThan14Days: ISchoolClassroomUserLoginHistory[]
}

export interface ISchoolClassroomScoreDiagnostic {
  identifier: string
  firstname: string
  lastname: string
  teacherFirstname: string | null
  teacherLastname: string | null
  tasks: ISchoolClassroomScoreDiagnosticTask[]
}

export interface ISchoolClassroomScoreDiagnosticTask {
  taskId: number
  taskTitle: string
  taskOrder: string
  diagScorePercentRounded: number | null
  diagEvalRangeName: string | null
}

export interface ISchoolClassroomScoreModule {
  identifier: string
  firstname: string
  lastname: string
  teacherFirstname: string | null
  teacherLastname: string | null
  taskId: number
  taskTitle: string
  taskOrder: string
  diagScorePercentRounded: number | null
  diagEvalRangeName: string | null
  moduleScorePercentRounded: number | null
  moduleEvalRangeName: string | null
  diagnosticModuleVariation: number | null
  learningGrains: ISchoolClassroomScoreModuleLearningGrain[]
}

export interface ISchoolClassroomScoreModuleLearningGrain {
  learningGrainId: number
  learningGrainTitle: string
  learningGrainOrder: string
  postQuizEvalRangeName: number | null
}
