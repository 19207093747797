<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// PRIMEVUE
import { default as PMessage } from 'primevue/message'
import { default as PButton } from 'primevue/button'

// VUE, VUE-ROUTER, etc.
import { computed, onMounted, ref, type Ref } from 'vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'

//Custom components and types
import type { ILearningModuleInput } from '@/assets/types/learning/LearningModuleTypes'
import type {
  ILearningGrain,
  ILearningGrainInput
} from '@/assets/types/learning/LearningGrainTypes'
import type { ILearningCourse } from '@/assets/types/learning/LearningCourses'
import { LearningModuleInputDto } from '@/assets/DTO/learning/learningModule.dto'
import { LearningEnums } from '@/assets/types/learning/enums'
import UpdateModuleHeader from '@/views/modules/blocks/UpdateModuleHeader.vue'
import LearningGrain from '@/views/modules/blocks/LearningGrain.vue'

// STORES
import { useModulesStore } from '@/stores/learningModules'
import { useLearningGrainsStore } from '@/stores/learningGrains'

// UTILS
import { useConfirm } from 'primevue/useconfirm'
import { deepClone } from '@/assets/utils'
import LearningTasks from '@/views/modules/blocks/LearningTasks.vue'
const route = useRoute()
const confirm = useConfirm()

// COMPUTED
const hasUnsavedChanges = computed(() => {
  if (learningModuleCopy.value === ({} as ILearningModuleInput)) return false
  return (
    JSON.stringify(learningModule.value) !== JSON.stringify(learningModuleCopy.value) &&
    !!fieldToUpdate.value
  )
})

// detect if we are creating a module
const isCreatingModule = computed(() => {
  return route.name === 'learning-course-add-module'
})

const canPublish = computed(() => {
  return (
    learningModule.value.title !== '' &&
    !!learningModule.value.learningGrains &&
    learningModule.value.learningGrains.length > 0
  )
})
// PROPS
const props = defineProps<{
  id: number
  moduleId?: number
  axis: string
  learningCourse: ILearningCourse
}>()

// DATA
const loading = ref(true)
const fieldToUpdate: Ref<string | null> = ref(null)
const ModuleHeader = ref<typeof UpdateModuleHeader | null>(null)
const scrollingArea: Ref<HTMLElement | null> = ref(null)
const learningModule: Ref<ILearningModuleInput> = ref({} as ILearningModuleInput)
const learningModuleCopy: Ref<ILearningModuleInput> = ref({} as ILearningModuleInput)
const currentDisplayedGrain: Ref<ILearningGrainInput> = ref({} as ILearningGrainInput)

// HAPPENS ON MOUNTED
onMounted(async () => {
  if (isCreatingModule.value)
    learningModule.value = new LearningModuleInputDto({
      id: 0,
      order: props.learningCourse.learningModules.length + 1,
      title: isCreatingModule.value ? 'Nouveau module' : '',
      status: LearningEnums.Status.DRAFT
    })
  else if (props.moduleId) {
    const module = await fetchModuleById(props.moduleId, props.learningCourse.id)

    learningModule.value = module.createLearninModuleIpnut()

    if (module.learningGrains && module.learningGrains.length > 0) {
      currentDisplayedGrain.value = module.learningGrains[0]
    }
  }

  if (route.query.grainId) {
    const grainIndex = learningModule.value.learningGrains?.findIndex(
      (g) => g.id === Number(route.query.grainId)
    )

    if (grainIndex && grainIndex !== -1) {
      displayGrain(grainIndex)
    }
  }

  loading.value = false
})

// METHODS
const { createModule, fetchModuleById, updateModule, updateModuleStatus } = useModulesStore()
const { createGrain, updateGrain, updateGrainStatus } = useLearningGrainsStore()
async function publishModule() {
  const updatedModule = await updateModuleStatus(
    LearningEnums.Status.ACTIVE,
    learningModule.value.id,
    props.id
  )

  if (updatedModule.status === LearningEnums.Status.ACTIVE) {
    learningModule.value = updatedModule.createLearninModuleIpnut()
  }
}

async function startUpdate(fieldName: string, copy?: ILearningModuleInput) {
  if (hasUnsavedChanges.value)
    confirm.require({
      message: 'Vous avez des changements non sauvegardés, voulez-vous les enregistrer ?',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: async () => {
        await validateUpdate()
      },
      reject: async () => {
        await cancelUpdate()
      }
    })

  fieldToUpdate.value = fieldName
  learningModuleCopy.value = deepClone(copy ? copy : learningModule.value)
}

async function cancelUpdate() {
  fieldToUpdate.value = null
  learningModule.value = deepClone(learningModuleCopy.value)
  learningModuleCopy.value = {} as ILearningModuleInput
}

async function validateUpdate(sendInput = true) {
  if (sendInput) await sendInputToAPI()

  fieldToUpdate.value = null
  learningModuleCopy.value = {} as ILearningModuleInput
}

async function sendInputToAPI() {
  if (isCreatingModule.value) {
    learningModule.value = (
      await createModule(learningModule.value, props.learningCourse.id)
    ).createLearninModuleIpnut()
    return
  } else if (fieldToUpdate.value?.includes('create_grain')) {
    const createdGrain = await createGrain(
      currentDisplayedGrain.value,
      learningModule.value.id,
      props.id
    )
    fieldToUpdate.value = `grain_${currentDisplayedGrain.value.order}`
    handleGrainUpdate(createdGrain, true)
    return
  } else if (fieldToUpdate.value?.includes('grain')) {
    const updatedGrain = await updateGrain(
      currentDisplayedGrain.value,
      learningModule.value.id,
      props.id
    )
    handleGrainUpdate(updatedGrain)
    return
  } else {
    learningModule.value = (
      await updateModule(learningModule.value, learningModule.value.id, props.id)
    ).createLearninModuleIpnut()
  }
}

function handleGrainUpdate(grain: ILearningGrainInput, isCreated = false) {
  if (isCreated && learningModule.value.learningGrains) {
    const index = learningModule.value.learningGrains.findIndex((g) => g.id === 0)
    learningModule.value.learningGrains[index] = grain
    currentDisplayedGrain.value = grain
  } else if (learningModule.value.learningGrains) {
    const index = learningModule.value.learningGrains.findIndex((g) => g.id === grain.id)
    learningModule.value.learningGrains[index] = grain
  }
}

function addGrainToModule() {
  if (fieldToUpdate.value && fieldToUpdate.value.includes('create_grain')) return
  const moduleCopy = deepClone(learningModule.value)

  if (learningModule.value.learningGrains === undefined) {
    learningModule.value.learningGrains = []
  }

  const grains = learningModule.value.learningGrains

  grains.push({
    id: 0,
    title: 'Nouveau grain',
    points: 0,
    order: learningModule.value.learningGrains.length + 1,
    status: LearningEnums.Status.DRAFT,
    learningSupports: []
  })

  displayGrain(learningModule.value.learningGrains.length - 1)
  startUpdate(`create_grain_${learningModule.value.learningGrains.length}`, moduleCopy)
}

async function deleteGrainFromModule() {
  const grain = await updateGrainStatus(
    LearningEnums.Status.ARCHIVED,
    currentDisplayedGrain.value.id,
    learningModule.value.id,
    props.id
  )

  if (grain.status === LearningEnums.Status.ARCHIVED) {
    const index = learningModule.value.learningGrains?.findIndex((g) => g.id === grain.id)

    if (index !== -1) {
      learningModule.value.learningGrains?.splice(index, 1)
      currentDisplayedGrain.value = {} as ILearningGrain
    }
  }
}

function displayGrain(learningGrainIndex: number) {
  if (learningModule.value.learningGrains === undefined) {
    learningModule.value.learningGrains = []
  }

  if (learningGrainIndex + 1 === currentDisplayedGrain.value.order) return

  currentDisplayedGrain.value = learningModule.value.learningGrains[learningGrainIndex]

  // If a grain is currently being edited, we cancel the update
  if (fieldToUpdate.value && fieldToUpdate.value.includes('grain')) {
    cancelUpdate()
  }

  // Scroll panel to the top
  if (scrollingArea.value && 'scrollTo' in scrollingArea.value) {
    scrollingArea.value?.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}

async function publishGrain(grainId: number) {
  loading.value = true
  const updatedGrain = await updateGrainStatus(
    LearningEnums.Status.ACTIVE,
    grainId,
    learningModule.value.id,
    props.id
  )

  if (updatedGrain.status === LearningEnums.Status.ACTIVE) {
    const index = learningModule.value.learningGrains?.findIndex((g) => g.id === grainId) as number

    if (index !== -1 && learningModule.value.learningGrains) {
      learningModule.value.learningGrains[index] = updatedGrain
      currentDisplayedGrain.value = updatedGrain
    }
  }

  loading.value = false
}

// Before route leave
onBeforeRouteLeave((to, from, next) => {
  if (hasUnsavedChanges.value) {
    confirm.require({
      message: 'Vous avez des changements non sauvegardés, voulez-vous les enregistrer ?',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        validateUpdate()
        next()
      },
      reject: () => {
        next()
      }
    })
  } else next()
})
</script>

<template>
  <div v-if="!loading" class="grid grid-nogutter">
    <update-module-header
      :field-to-update="fieldToUpdate"
      :add-grain-to-module="addGrainToModule"
      :start-update="startUpdate"
      :cancel-update="cancelUpdate"
      :validate-update="validateUpdate"
      v-model:module="learningModule"
      :domain="learningCourse.domain"
      :can-publish="canPublish"
      :publish-module="publishModule"
      :learning-course-id="learningCourse.id"
      :learning-course-title="learningCourse.title"
      :is-creating="isCreatingModule"
      ref="ModuleHeader"
    />

    <div
      class="border-right-1 surface-border surface-b py-5 px-5 overflow-auto col-4"
      :style="`height: calc(100vh - ${ModuleHeader?.Header.offsetHeight}px)`"
    >
      <learning-tasks
        :field-to-update="fieldToUpdate"
        :domain="learningCourse.domain"
        :learning-course-id="learningCourse.id"
        :validate-update="validateUpdate"
        v-model:module="learningModule"
        :is-creating="isCreatingModule"
      />

      <div class="p-5 border-1 border-dashed border-round border-300 bg-white mt-3">
        <div class="font-semibold text-color-secondary">Grains</div>

        <p
          v-for="(grain, index) in learningModule.learningGrains"
          :key="`grain_${index}`"
          class="mt-3"
          :class="{
            'text-primary': currentDisplayedGrain.id === grain.id,
            'cursor-pointer': currentDisplayedGrain.id !== grain.id,
            'font-bold': currentDisplayedGrain.id === grain.id
          }"
          @click="displayGrain(index)"
        >
          <i class="pi pi-file-edit text-color-secondary" />
          {{ grain.title }}
        </p>

        <p-button
          label="Ajouter un grain"
          icon="pi pi-plus"
          class="mt-3 pointer-events-auto"
          @click="addGrainToModule"
          outlined
          size="small"
          :disabled="isCreatingModule"
          v-tooltip="
            isCreatingModule ? 'Veuillez enregistrer le module avant d\'ajouter un grain' : ''
          "
        />
      </div>
    </div>

    <div
      class="overflow-auto relative col-8"
      :style="`height: calc(100vh - ${ModuleHeader?.Header.offsetHeight}px)`"
      ref="scrollingArea"
    >
      <div class="p-5 flex flex-column">
        <p-message
          v-if="
            !isCreatingModule &&
            learningModule.learningGrains &&
            learningModule.learningGrains.length === 0
          "
        >
          <p>
            <b>Info</b> A présent vous pouvez ajouter des grains, qui contiendront les supports de
            formation. Une fois tous vos grains ajoutés, vous pourrez valider la création de votre
            module.
          </p>
        </p-message>

        <learning-grain
          v-if="currentDisplayedGrain.id >= 0"
          v-model="currentDisplayedGrain"
          :learning-course-id="props.id"
          :learning-module="learningModule"
          :is-editing="!!fieldToUpdate?.includes(`grain_${currentDisplayedGrain.order}`)"
          @start-grain-update="startUpdate(`grain_${currentDisplayedGrain.order}`)"
          @cancel="cancelUpdate()"
          @delete="deleteGrainFromModule()"
          @validate="validateUpdate()"
          @publish="(grainId: number) => publishGrain(grainId)"
        />
      </div>
    </div>
  </div>
</template>
