import type {
  ILearningGrain,
  ILearningGrainInput
} from '@/assets/types/learning/LearningGrainTypes'
import type { ILearningSupport } from '@/assets/types/learning/LearningSupportTypes'
import { LearningEnums } from '@/assets/types/learning/enums'

export class LearningGrainInputDto implements ILearningGrainInput {
  id: number
  title: string
  status: LearningEnums.Status
  points: number
  order: number
  learningSupports: ILearningSupport[]

  constructor(data: ILearningGrainInput | ILearningGrain) {
    this.id = data.id
    this.title = data.title
    this.status = data.status
    this.points = data.points
    this.order = data.order
    this.learningSupports = data.learningSupports
  }
}
