<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */
// Vue, Vue Router etc
import { useRouter } from 'vue-router'
import { computed, onMounted, ref } from 'vue'

// Stores
import { useLearningCoursesStore } from '@/stores/learningCourses'
import { useReferentialStore } from '@/stores/referential'
import { storeToRefs } from 'pinia'

// Custom components and types

// Utility
const router = useRouter()
const {  fetchAllCourses, fetchCourse } = useLearningCoursesStore()
const { getAxisTitleFromDomainId } = useReferentialStore()

// Data
const loading = ref(true)
const { learningCourse } = storeToRefs(useLearningCoursesStore())

// Props
const props = defineProps<{
  id: number
}>()

// Lifecycle hooks
onMounted(async () => {
  // Fetch all courses if we come from another page than the course list
  const from = router.options.history.state.back
  if (from !== '/parcours') await fetchAllCourses()

  learningCourse.value = await fetchCourse(props.id)

  loading.value = false
})


const axis = computed(() => {
  return getAxisTitleFromDomainId(learningCourse.value.domain.id)
})
</script>

<template>
  <router-view v-if="!loading" :learningCourse="learningCourse" :axis="axis" />
</template>
