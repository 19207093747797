<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { useSchoolClassroomStore } from '@/stores/schoolClassroom'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import GroupDetailAttendance from '@/views/dashboard/blocks/details/GroupDetailAttendance.vue'
import GroupDetailSummary from '@/views/dashboard/blocks/details/GroupDetailSummary.vue'
import GroupDetailProgress from '@/views/dashboard/blocks/details/GroupDetailProgress.vue'

const Header = ref<HTMLElement | null>(null)

// Import Primevue services
const toast = useToast()

// Import stores
const { fetchSchoolClassroomById, resetClassroom } = useSchoolClassroomStore()
const { classroom } = storeToRefs(useSchoolClassroomStore())

// Inherit from router
const props = defineProps<{
  schoolId: number
  groupId: number
}>()

onMounted(async () => {
  try {
    await fetchSchoolClassroomById(props.schoolId, props.groupId)
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: 'Impossible de charger les détails du groupe',
      life: 5000
    })
  }
})

onUnmounted(() => {
  resetClassroom()
})
</script>

<template>
  <div class="col-12 grid grid-nogutter p-5 py-3 border-bottom-1 border-300" ref="Header">
    <div class="col justify-content-start flex flex-column gap-2">
      <h3 class="uppercase text-left">{{ classroom.data.name }}</h3>
      <router-link
        :to="{ name: 'dashboard' }"
        class="flex align-items-center gap-2 w-full text-color-secondary no-underline"
        >{{ '< Revenir à la liste des groupes' }}
      </router-link>
    </div>
  </div>

  <div
    class="col-12 p-5 bg-bo-brand-tertiary flex-grow-1 overflow-y-auto gap-5 flex flex-column"
    :style="`height: calc(100vh + 1rem - ${Header?.offsetHeight}px)`"
  >
    <!-- Assiduité des élèves -->
    <group-detail-attendance :schoolId="props.schoolId" :groupId="props.groupId" />

    <!-- Synthèse - suivi des élèves -->
    <group-detail-summary />

    <!-- Progression du groupe -->
    <group-detail-progress :schoolId="props.schoolId" :groupId="props.groupId" />
  </div>
</template>
