import type { ILearningQuiz } from '@/assets/types/learning/learningQuiz'
import { LearningEnums } from '@/assets/types/learning/enums'
import type { IFeedbacks, IQuestion } from '@/assets/types/learning/QuizQuestion'
import type {
  IMatchingAnswer,
  IMCQAnswer,
  IQuestionAnswer,
  ISequencingAnswer,
  ITrueFalseAnswer,
  IUCQAnswer
} from '@/assets/types/learning/answers'

export class LearningQuizDto implements ILearningQuiz {
  id: number
  type: LearningEnums.QuizType
  ready: boolean
  questions: IQuestion[]

  constructor(data: ILearningQuiz) {
    this.id = data.id
    this.type = data.type
    this.ready = data.ready
    this.questions = data.questions
  }

  isValid(): boolean {
    return this.questions.every((question) => new LearningQuizQuestion(question).isValid())
  }
}

export class LearningQuizQuestion implements IQuestion {
  id: number
  label: string
  order: number
  type: LearningEnums.QuestionType
  answers: IQuestionAnswer[]
  feedbacks: IFeedbacks

  constructor(data: IQuestion) {
    this.id = data.id
    this.label = data.label
    this.order = data.order
    this.answers = data.answers
    this.feedbacks = data.feedbacks
    this.type = data.type
    this.answers = data.answers
  }

  isValid(): boolean {
    return (
      !!this.label &&
      !!this.order &&
      !!this.type &&
      this.answers.every((answer) => new QuizQuestionAnswer(answer).isValid())
    )
  }
}

export class QuizQuestionAnswer {
  label?: string
  isCorrect?: boolean
  type: LearningEnums.QuestionType
  left?: { type: string; value: string }
  right?: { type: string; value: string }
  data?: { type: string; value: string }
  order?: number

  constructor(answer: IQuestionAnswer) {
    this.type = answer.type
    switch (answer.type) {
      case LearningEnums.QuestionType.MULTIPLE_CHOICE:
      case LearningEnums.QuestionType.SINGLE_CHOICE:
        this.label = (answer as IMCQAnswer | IUCQAnswer).label
        this.isCorrect = (answer as IMCQAnswer | IUCQAnswer).isCorrect
        break
      case LearningEnums.QuestionType.TRUE_FALSE:
        this.label = (answer as ITrueFalseAnswer).label
        this.isCorrect = (answer as ITrueFalseAnswer).isCorrect
        break
      case LearningEnums.QuestionType.MATCHING:
        this.left = (answer as IMatchingAnswer).left
        this.right = (answer as IMatchingAnswer).right
        break
      case LearningEnums.QuestionType.SEQUENCING:
        this.data = (answer as ISequencingAnswer).data
        this.order = (answer as ISequencingAnswer).order
        break
    }
  }

  isValid(): boolean {
    switch (this.type) {
      case LearningEnums.QuestionType.MULTIPLE_CHOICE:
        return !!this.label && typeof this.isCorrect === 'boolean'
      case LearningEnums.QuestionType.SINGLE_CHOICE:
        return !!this.label && typeof this.isCorrect === 'boolean'
      case LearningEnums.QuestionType.TRUE_FALSE:
        return (
          this.label === 'Vrai' || (this.label === 'Faux' && typeof this.isCorrect === 'boolean')
        )
      case LearningEnums.QuestionType.MATCHING:
        return (
          !!this.left &&
          !!this.left.type &&
          !!this.left.value &&
          !!this.right &&
          !!this.right.type &&
          !!this.right.value
        )
      case LearningEnums.QuestionType.SEQUENCING:
        return (
          !!this.data && !!this.data.type && !!this.data.value && typeof this.order === 'number'
        )
      default:
        return false
    }
  }
}
