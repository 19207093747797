import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
  type RouteMeta
} from 'vue-router'
import { routes } from '@/router/routes'

export interface CustomRouteMeta extends RouteMeta {
  assertQueries: (route: RouteLocationNormalized) => boolean
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

export default router
