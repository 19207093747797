import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { ILearningQuiz } from '@/assets/types/learning/learningQuiz'
import nabooApi from '@/services/nabooApi'
import { NabooError } from '@/assets/classes/Error'
import { useToast } from 'primevue/usetoast'
import { LearningQuizDto } from '@/assets/DTO/learning/learningQuiz.dto'
import { useLearningCoursesStore } from '@/stores/learningCourses'

export const useLearningQuizStore = defineStore('learningQuiz', () => {
  // Utility functions
  const toast = useToast()
  const courseStore = useLearningCoursesStore()

  const quizsList: Ref<ILearningQuiz[]> = ref([])

  //MUTATIONS
  function addQuizToList(quiz: ILearningQuiz) {
    quizsList.value.push(quiz)
  }

  function updateQuizInList(quiz: ILearningQuiz) {
    const index = quizsList.value.findIndex((q) => q.id === quiz.id)
    if (index !== -1) {
      quizsList.value[index] = quiz
    }
  }

  // ACTIONS
  async function fetchQuiz(quizId: number) {
    try {
      const quiz = await nabooApi.getQuiz(quizId)
      addQuizToList(quiz)

      return Promise.resolve(quiz)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningQuiz.ts - getQuiz')
        error.setMessage('Une erreur est survenue lors de la récupération du quiz.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération du quiz.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject(nabooError)
    }
  }

  async function updateQuiz(quiz: ILearningQuiz) {
    const quizToUpdate = new LearningQuizDto(quiz)

    try {
      if (!quizToUpdate.isValid()) {
        throw new NabooError({
          message: "Le quiz n'est pas valide.",
          status: 0,
          name: 'Erreur',
          code: 'ERR_INVALID',
          error: new Error("Le quiz n'est pas valide.")
        })
      }

      const updatedQuiz = await nabooApi.updateQuiz(quiz.id, quiz)
      updateQuizInList(updatedQuiz)
      courseStore.updateQuizInCourse(updatedQuiz)

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'Le quiz a été mis à jour.',
        life: 3000
      })

      return Promise.resolve(updatedQuiz)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningQuiz.ts - updateQuiz')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la mise à jour du quiz.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject(nabooError)
    }
  }

  async function markQuizAsReady(quiz: ILearningQuiz) {
    const quizToUpdate = new LearningQuizDto(quiz)

    try {
      if (!quizToUpdate.isValid() || quizToUpdate.questions.length == 0) {
        throw new NabooError({
          message: "Le quiz n'est pas valide.",
          status: 0,
          name: 'Erreur',
          code: 'ERR_INVALID',
          error: new Error("Le quiz n'est pas valide.")
        })
      }

      const readyQuiz = await nabooApi.markQuizAsReady(quiz.id)
      updateQuizInList(readyQuiz)
      courseStore.updateQuizInCourse(readyQuiz)

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'Le quiz a été publié.',
        life: 3000
      })

      return Promise.resolve(readyQuiz)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningQuiz.ts - markQuizAsReady')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la publication du quiz.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject(nabooError)
    }
  }

  return {
    quizsList,
    addQuizToList,
    updateQuiz,
    markQuizAsReady,
    fetchQuiz
  }
})
