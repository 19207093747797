import API from '@/services/API'
import { LearningQuizResponseDto } from '@/assets/DTO/learning/learningQuiz.response.dto'
import { NabooError } from '@/assets/classes/Error'
import type { ILearningQuiz } from '@/assets/types/learning/learningQuiz'

export const learningQuiz = {
  async getQuiz(quizId: number) {
    try {
      const response = await API().get(`/quiz/${quizId}`)
      const quiz = new LearningQuizResponseDto(response.data)

      return Promise.resolve(quiz)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningQuiz.ts - getQuiz')
        error.setMessage('Une erreur est survenue lors de la récupération du quiz.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération du quiz.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  },

  async updateQuiz(quizId: number, data: ILearningQuiz) {
    try {
      const response = await API().put(`/quiz/${quizId}`, data.questions)
      const quiz = new LearningQuizResponseDto(response.data)

      return Promise.resolve(quiz)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningQuiz.ts - updateQuiz')
        error.setMessage('Une erreur est survenue lors de la mise à jour du quiz.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la mise à jour du quiz.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  },

  /**
   * Mark a quiz as ready
   * @param quizId
   */
  async markQuizAsReady(quizId: number) {
    try {
      const response = await API().put(`/quiz/${quizId}/ready`)
      const quiz = new LearningQuizResponseDto(response.data)

      return Promise.resolve(quiz)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningQuiz.ts - markQuizAsReady')
        error.setMessage('Une erreur est survenue lors de la publication du quiz.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la publication du quiz.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  }
}
