import type { IAxis, IDomain } from '@/assets/types/Referential'

export class GetReferentialResponseDto {
  referential: IAxis[]

  constructor(axes: IAxis[]) {
    this.referential = axes
  }
}

export class GetAxisByIdResponseDto implements IAxis {
  id: number
  title: string
  order: number
  domains: IDomain[]

  constructor(axis: IAxis) {
    this.id = axis.id
    this.title = axis.title
    this.order = axis.order
    this.domains = axis.domains
  }
}
