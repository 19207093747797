import API from '@/services/API'
import type { AxiosResponse } from 'axios'
import type { ISchool } from '@/assets/types/school/ISchool'
import { SchoolResponseDto } from '@/assets/DTO/school/school.response.dto'
import { NabooError } from '@/assets/classes/Error'

export const school = {
  /**
   * Get all schools
   */
  async getAllSchools(): Promise<SchoolResponseDto[]> {
    try {
      const response: AxiosResponse<ISchool[]> = await API().get('admin/schools')
      return Promise.resolve(response.data.map((school: ISchool) => new SchoolResponseDto(school)))
    } catch (error) {
      if (error instanceof NabooError) {
        error.setStack('API/school.ts - getAllSchools')
        error.setMessage('Une erreur est survenue lors de la récupération des établissements.')
      }
      return Promise.reject(error)
    }
  },

  /**
   * Get a school by its id
   * @param schoolId
   */
  async getSchoolById(schoolId: number): Promise<SchoolResponseDto> {
    try {
      const response: AxiosResponse<ISchool> = await API().get(`admin/schools/${schoolId}`)
      return Promise.resolve(new SchoolResponseDto(response.data))
    } catch (error) {
      if (error instanceof NabooError) {
        error.setStack('API/school.ts - getSchoolById')
        error.setMessage("Une erreur est survenue lors de la récupération de l'établissement.")
      }
      return Promise.reject(error)
    }
  },

  /**
   * Get all schools of the current user
   */
  async getSchoolsForMe(): Promise<SchoolResponseDto[]> {
    try {
      const response: AxiosResponse<ISchool[]> = await API().get('admin/schools/me')
      return Promise.resolve(response.data.map((school: ISchool) => new SchoolResponseDto(school)))
    } catch (error) {
      if (error instanceof NabooError) {
        error.setStack('API/school.ts - getSchoolForMe')
        error.setMessage('Une erreur est survenue lors de la récupération de votre établissement.')
      }
      return Promise.reject(error)
    }
  }
}
