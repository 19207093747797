import API from '@/services/API'
import { NabooError } from '@/assets/classes/Error'
import * as Sentry from '@sentry/vue'
import { GetReferentialResponseDto } from '@/assets/DTO/referential/referential.response.dto'

export const referential = {
  async getAllAxises() {
    try {
      const response = await API().get('referential/')
      return Promise.resolve(new GetReferentialResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/referential - getAllAxises')
        error.setMessage('Une erreur est survenue lors de la récupération des données.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération des données.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  }
}
