import { defineStore } from 'pinia'
import type {
  ILearningGrainInput
} from '@/assets/types/learning/LearningGrainTypes'
import nabooApi from '@/services/nabooApi'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { NabooError } from '@/assets/classes/Error'
import { useToast } from 'primevue/usetoast'
import { LearningGrainInputDto } from '@/assets/DTO/learning/learningGrain.dto'
import { LearningEnums } from '@/assets/types/learning/enums'
import type { LearningGrainResponseDto } from '@/assets/DTO/learning/learningGrain.response.dto'
import { useLearningCoursesStore } from '@/stores/learningCourses'

export const useLearningGrainsStore = defineStore('learningGrains', () => {
  // Utility functions
  const toast = useToast()
  const courseStore = useLearningCoursesStore()

  // STATE
  const grains: Ref<LearningGrainResponseDto[]> = ref([])

  // MUTATIONS
  function setGrains(newGrains: LearningGrainResponseDto[]) {
    grains.value = newGrains
  }

  function addGrains(newGrains: LearningGrainResponseDto[]) {
    grains.value = grains.value.concat(newGrains)
  }

  function addGrain(grain: LearningGrainResponseDto) {
    grains.value.push(grain)
  }

  function updateGrainData(grain: LearningGrainResponseDto) {
    const index = grains.value.findIndex((g) => g.id === grain.id)
    if (index !== -1) {
      grains.value[index] = grain
    }
  }

  function sortGrains() {
    grains.value.sort((a, b) => a.order - b.order)
  }

  // ACTIONS
  async function createGrain(
    grain: ILearningGrainInput,
    learningModuleId: number,
    learningCourseId: number
  ) {
    try {
      const response = await nabooApi.createLearningGrain(
        new LearningGrainInputDto(grain),
        learningModuleId,
        learningCourseId
      )

      addGrain(response)
      courseStore.addGrainToModule(response, learningModuleId)

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'Le grain a été créé avec succès.',
        life: 3000
      })

      return Promise.resolve(response)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningGrains.ts - createLearningGrain')
        error.setMessage('Une erreur est survenue lors de la création du grain.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la création du grain.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: nabooError.name,
        detail: nabooError.message,
        life: 3000
      })
    }

    return Promise.reject()
  }

  async function updateGrain(
    learningGrain: ILearningGrainInput,
    learningModuleId: number,
    learningCourseId: number
  ) {
    try {
      const response = await nabooApi.updateLearningGrain(
        learningGrain,
        learningModuleId,
        learningCourseId
      )

      updateGrainData(response)

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'Le grain a été mis à jour avec succès.',
        life: 3000
      })

      return Promise.resolve(response)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningGrains.ts - updateLearningGrain')
        error.setMessage('Une erreur est survenue lors de la mise à jour du grain.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la mise à jour du grain.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: nabooError.name,
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject()
    }
  }

  async function updateGrainStatus(
    status: LearningEnums.Status,
    grainId: number,
    learningModuleId: number,
    learningCourseId: number
  ) {
    const grain = grains.value.find((g) => g.id === grainId)

    if (!grain) {
      const nabooError = new NabooError({
        message: 'Le grain n\'a pas été trouvé.',
        status: 0,
        name: 'Erreur',
        code: 'ERR_UNKNOWN',
        error: new Error('No grain found')
      })

      toast.add({
        severity: 'error',
        summary: nabooError.name,
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject(nabooError)
    }

    // Check if the grain is already in the desired status
    if (grain.status === status) {
      toast.add({
        severity: 'info',
        summary: 'Information',
        detail: `Le grain est déjà dans le statut ${LearningEnums.statusLabel(status)}.`,
        life: 3000
      })
    }

    // If status is ACTIVE, check if the grain has at least one support and that the quizs are valid
    if (status === LearningEnums.Status.ACTIVE) {
      const support = grain.supportsCount()
      const quizsAreValid = grain.quizsAreValid()

      if (support === 0) {
        const nabooError = new NabooError({
          message: 'Le grain doit avoir au moins un support pour être activé.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: new Error('No support found')
        })

        toast.add({
          severity: 'error',
          summary: nabooError.name,
          detail: nabooError.message,
          life: 3000
        })

        return Promise.reject(nabooError)
      }

      if (!quizsAreValid) {
        const nabooError = new NabooError({
          message: 'Les quizs ne sont pas valides pour ce grain.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: new Error('Quizs are not valid')
        })

        toast.add({
          severity: 'error',
          summary: nabooError.name,
          detail: nabooError.message,
          life: 3000
        })

        return Promise.reject(nabooError)
      }
    }

    try {
      const response = await nabooApi.updateGrainStatus(
        status,
        grainId,
        learningModuleId,
        learningCourseId
      )

      updateGrainData(response)

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: response.status === LearningEnums.Status.ARCHIVED ? 'Le grain a bien été supprimé' : 'Le statut du grain a été mis à jour avec succès.',
        life: 3000
      })

      return Promise.resolve(response)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningGrains.ts - updateGrainStatus')
        error.setMessage('Une erreur est survenue lors de la mise à jour du statut du grain.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la mise à jour du statut du grain.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: nabooError.name,
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject()
    }
  }

  return {
    grains,
    setGrains,
    addGrain,
    addGrains,
    sortGrains,
    createGrain,
    updateGrain,
    updateGrainStatus
  }
})
