<script setup lang="ts">
/*
 *
 * Imports
 *
 * */

//PrimeVue
import { default as PButton } from 'primevue/button'
import { default as PDropdown } from 'primevue/dropdown'

//Vue, router
import { computed, type ModelRef } from 'vue'
import { useRouter } from 'vue-router'

//Custom components and types
import type { ILearningModuleInput } from '@/assets/types/learning/LearningModuleTypes'
import type { IDomain } from '@/assets/types/Referential'

//Utils
const router = useRouter()
const modelModule: ModelRef<ILearningModuleInput> = defineModel('module', { required: true })

const props = defineProps<{
  fieldToUpdate: string | null
  validateUpdate: (sendInput?: boolean) => void
  domain: IDomain
  learningCourseId: number
  isCreating: boolean
}>()

const taskOptions = computed(() => {
  return props.domain.tasks.map((task) => ({
    label: task.title,
    value: task.id
  }))
})

const taskLabel = computed(() => {
  const label = taskOptions.value.find((task) => task.value === modelModule.value.taskId)?.label
  return label ? label : 'Aucune tâche ne correspond'
})
</script>

<template>
  <div class="w-full">
    <h5 class="text-color font-bold">Tâche liée (référentiel Paideia)</h5>
    <p-dropdown
      v-if="isCreating"
      v-model="modelModule.taskId"
      :options="taskOptions"
      option-label="label"
      option-value="value"
      class="w-full"
      placeholder="Choisissez une tâche dans la liste"
    />
    <template v-else>
      <span class="p-text-secondary">
        {{ taskLabel }}
      </span>
    </template>
  </div>

  <div class="w-full flex gap-3" v-if="isCreating">
    <p-button
      label="Annuler"
      class="mt-3"
      @click="router.push({ name: 'learning-course-update', params: { id: learningCourseId } })"
      severity="secondary"
      size="small"
    />
    <p-button
      label="Enregistrer"
      class="mt-3"
      @click="validateUpdate()"
      severity="primary"
      size="small"
      :disabled="!modelModule.taskId"
    />
  </div>
</template>
