import API from '@/services/API'
import type { ISchoolYear } from '@/assets/types/school/ISchoolYear'
import type { AxiosResponse } from 'axios'
import { SchoolYearResponseDto } from '@/assets/DTO/school/school.year.response.dto'
import { NabooError } from '@/assets/classes/Error'

export const schoolYear = {
  async getAllSchoolYears(): Promise<SchoolYearResponseDto[]> {
    try {
      const response: AxiosResponse<ISchoolYear[]> = await API().get('admin/school-years')
      return Promise.resolve(
        response.data.map((schoolYear: ISchoolYear) => new SchoolYearResponseDto(schoolYear))
      )
    } catch (error) {
      if (error instanceof NabooError) {
        error.setStack('API/schoolYear.ts - getAllSchoolYears')
        error.setMessage('Une erreur est survenue lors de la récupération années scolaires.')
      }
      return Promise.reject(error)
    }
  }
}
