import type { ILearningModuleInput } from '@/assets/types/learning/LearningModuleTypes'
import { LearningEnums } from '@/assets/types/learning/enums'
import type { ILearningGrainInput } from '@/assets/types/learning/LearningGrainTypes'

export class LearningModuleInputDto implements ILearningModuleInput {
  id: number
  title: string
  order: number
  status: LearningEnums.Status
  taskId?: number
  learningGrains?: ILearningGrainInput[]

  constructor(learningModuleInput: ILearningModuleInput) {
    this.id = learningModuleInput.id
    this.order = learningModuleInput.order
    this.title = learningModuleInput.title
    this.status = learningModuleInput.status
    this.taskId = learningModuleInput.taskId
    this.learningGrains = learningModuleInput.learningGrains
  }
}
