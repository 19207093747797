import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { NabooError } from '@/assets/classes/Error'
import { SchoolYearResponseDto } from '@/assets/DTO/school/school.year.response.dto'

export const useSchoolYearStore = defineStore('SchoolYear', () => {
  const hasError = ref(false)
  const schoolYearList = ref<SchoolYearResponseDto[]>([])

  // ACTIONS
  async function fetchAllSchoolYears() {
    try {
      hasError.value = false
      const response: SchoolYearResponseDto[] = await nabooApi.getAllSchoolYears()
      if (response) schoolYearList.value = response
      return Promise.resolve()
    } catch (error) {
      if (error instanceof NabooError) {
        hasError.value = true
      }
    }
  }

  // GETTERS (COMPUTED)
  const getCurrentSchoolYear = computed(() => {
    return schoolYearList.value.find((schoolYear) => schoolYear.isCurrent)
  })

  return { schoolYearList, fetchAllSchoolYears, getCurrentSchoolYear }
})
