import API from '@/services/API'
import { LearningCourseInputDto } from '@/assets/DTO/learning/learningCourse.dto'
import {
  GetAllLearningCoursesResponseDto,
  LearningCourseNamesResponseDto,
  LearningCourseResponseDto
} from '@/assets/DTO/learning/learningCourse.response.dto'
import { NabooError } from '@/assets/classes/Error'
import { LearningEnums } from '@/assets/types/learning/enums'
import qs from 'qs'

const coursesDefaultOptions = {
  withModules: true,
  withGrains: false,
  withSupports: false,
  withDomains: false,
  status: [LearningEnums.Status.ACTIVE, LearningEnums.Status.DRAFT]
}

const courseDefaultOptions = {
  withModules: true,
  withGrains: true,
  withSupports: true,
  withDomains: true,
  status: [LearningEnums.Status.ACTIVE, LearningEnums.Status.DRAFT]
}

export const learningCourses = {
  async getAllCourses(options = coursesDefaultOptions) {
    try {
      const response = await API().get('learning/courses', {
        params: options,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
      })
      const courses = new GetAllLearningCoursesResponseDto(response.data)
      return Promise.resolve(courses)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningCourses.ts - getAllCourses')
        error.setMessage(
          'Une erreur est survenue lors de la récupération des données des parcours.'
        )
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération des données.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  },

  async getLearningCourseById(id: number, options = courseDefaultOptions) {
    try {
      const response = await API().get(`learning/courses/${id}`, {
        params: options,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
      })
      return Promise.resolve(new LearningCourseResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningCourses.ts - getLearningCourseById')
        error.setMessage('Une erreur est survenue lors de la récupération du parcours.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération du parcours.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  },

  async createLearningCourse(input: LearningCourseInputDto) {
    try {
      const response = await API().post('learning/courses', input)
      return Promise.resolve(new LearningCourseResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningCourses.ts - createLearningCourse')
        error.setMessage('Une erreur est survenue lors de la création du parcours.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la création du parcours.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  },

  async updateLearningCourseStatus(id: number, status: string) {
    try {
      const response = await API().put(`learning/courses/${id}/status`, { status })
      return Promise.resolve(new LearningCourseResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningCourses.ts - updateLearningCourseStatus')
        error.setMessage('Une erreur est survenue lors de la mise à jour du statut du parcours.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la mise à jour du statut du parcours.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  },

  async getAllLearningCourseNames() {
    try {
      const response = await API().get('learning/courses/names')
      return Promise.resolve(new LearningCourseNamesResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningCourses.ts - getAllLearningCourseNames')
        error.setMessage('Une erreur est survenue lors de la récupération des noms des parcours.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération des noms des parcours.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  }
}
