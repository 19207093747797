<script setup lang="ts">
import { useSchoolStore } from '@/stores/school'
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import GroupListSchool from '@/views/dashboard/blocks/list/GroupListSchool.vue'

const { fetchSchools } = useSchoolStore()
const { schoolList } = storeToRefs(useSchoolStore())

const Header = ref<HTMLElement | null>(null)

onMounted(async () => {
  await fetchSchools()
})
</script>

<template>
  <div class="col-12 grid grid-nogutter p-5 py-3 border-bottom-1 border-300" ref="Header">
    <div class="col justify-content-start flex flex-column gap-2">
      <h3 class="uppercase text-left">Liste des groupes</h3>
    </div>
  </div>

  <div
    class="col-12 p-5 bg-bo-brand-tertiary flex-grow-1 overflow-y-auto gap-5 flex flex-column"
    :style="`height: calc(100vh + 1rem - ${Header?.offsetHeight}px)`"
  >
    <group-list-school v-for="school in schoolList" :key="`school_${school.id}`" :school="school" />
  </div>
</template>
