<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// PrimeVue
import { default as PDataTable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { default as PTag } from 'primevue/tag'
import { default as PButton } from 'primevue/button'

// VUE, VUE ROUTER ETC
import { useRouter } from 'vue-router'

// Custom components and types
import type { ILearningTask } from '@/assets/types/Referential'
import { LearningEnums } from '@/assets/types/learning/enums'
import type { ILearningQuiz } from '@/assets/types/learning/learningQuiz'

const router = useRouter()

const props = defineProps<{
  tasks: ILearningTask[]
  learningCourseId: number
}>()

function editQuiz(quizId: number, taskId: number) {
  router.push({
    name: 'learning-course-update-quiz',
    params: { id: props.learningCourseId, quizId },
    query: { taskId: taskId, quizType: LearningEnums.QuizType.DIAG }
  })
}

function getQuizCompletion(quiz: ILearningQuiz) {
  const numberOfQuestions = quiz.questions.length
  let text
  let color: string

  if (numberOfQuestions === 0) {
    text = 'Quiz vide'
    color = 'secondary'
  } else {
    text = `${numberOfQuestions} question${numberOfQuestions > 1 ? 's' : ''}`
    color = 'warning'
  }

  if (numberOfQuestions > 4) color = 'success'

  return {
    text,
    color
  }
}
</script>

<template>
  <div>
    <h2 class="mb-3">Liste des tâches</h2>
    <p-data-table :value="tasks">
      <p-column field="title" header="Tâche"></p-column>
      <p-column field="quiz" header="Questions (quiz diagnostic)">
        <template #body="{ data }: { data: ILearningTask }">
          <p-tag :severity="getQuizCompletion(data.quiz).color">
            {{ getQuizCompletion(data.quiz).text }}
          </p-tag>
        </template>
      </p-column>
      <p-column field="description" header="Actions" style="width: 15rem">
        <template #body="{ data }: { data: ILearningTask }">
          <p-button
            label="Editer le quiz"
            text
            severity="secondary"
            icon="pi pi-pencil"
            class="mr-2"
            size="small"
            @click="editQuiz(data.quiz.id, data.id)"
          />
        </template>
      </p-column>
    </p-data-table>
  </div>
</template>
