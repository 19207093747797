<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// PRIMEVUE
import { default as PInputGroup } from 'primevue/inputgroup'
import { default as PTextarea } from 'primevue/textarea'
import { default as PInputGroupAddon } from 'primevue/inputgroupaddon'
import { default as PButton } from 'primevue/button'

// Custom components and types
import type { IMatchingAnswer } from '@/assets/types/learning/answers'

// VUE, VUE ROUTER, ETC...
import { type ModelRef } from 'vue'
import { LearningEnums } from '@/assets/types/learning/enums'

const answers: ModelRef<IMatchingAnswer[]> = defineModel('answers', { required: true })

function addQuestion() {
  answers.value.push({
    type: LearningEnums.QuestionType.MATCHING,
    right: {
      type: 'TEXT',
      value: ''
    },
    left: {
      type: 'TEXT',
      value: ''
    }
  })
}

function getLetterFromNumber(num: number) {
  const a_CODE = 97 // Code ASCII pour 'a'
  return String.fromCharCode(a_CODE + num)
}

function deleteAnswer(index: number) {
  answers.value.splice(index, 1)
}
</script>

<template>
  <div class="flex flex-column gap-1" >
    <div v-for="(answer, i) in answers" :key="`answer_${i}_${answer.id}`" class="grid">
      <div class="col-12 flex gap-1 align-items-center">
        <p>Paire {{ i + 1 }}</p>
        <p-button
          icon="pi pi-trash"
          size="small"
          outlined
          text
          class="p-1"
          severity="secondary"
          @click="() => deleteAnswer(i)"
        />
      </div>
      <div class="col-6">
        <p-input-group>
          <p-input-group-addon>
            <label>{{ i }}</label>
          </p-input-group-addon>
          <p-textarea
            v-model="answer.right.value"
            style="min-height: 50px; height: 50px"
            placeholder="Texte de la réponse"
          />
        </p-input-group>
      </div>

      <div class="col-6">
        <p-input-group>
          <p-input-group-addon>
            <label>{{ getLetterFromNumber(i) }}</label>
          </p-input-group-addon>
          <p-textarea
            v-model="answer.left.value"
            style="min-height: 50px; height: 50px"
            placeholder="Texte de la réponse"
          />
        </p-input-group>
      </div>
    </div>
  </div>

  <p-button
    label="Ajouter une paire"
    icon="pi pi-plus"
    class="mt-3"
    size="small"
    outlined
    @click="addQuestion"
  />
</template>
