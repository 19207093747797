import type { ISchoolYear } from '@/assets/types/school/ISchoolYear'

export class SchoolYearResponseDto {
  id: number
  name: string
  startDate: Date
  endDate: Date
  isCurrent: boolean

  constructor(input: ISchoolYear) {
    this.id = input.id
    this.name = input.name
    this.startDate = input.startDate
    this.endDate = input.endDate
    this.isCurrent = input.isCurrent
  }
}
