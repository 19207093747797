import { LearningEnums } from '@/assets/types/learning/enums'
import type { ILearningCourseUrlOptions } from '@/assets/types/learning/LearningCourses'

interface ILearningCourseInterface {
  id: number
  title: string
  status: LearningEnums.Status
  order: number
  name: string
  domainId?: number
}

export class LearningCourseInputDto {
  id: number
  title: string
  status: LearningEnums.Status
  order: number
  name: string
  domainId?: number

  constructor(obj: ILearningCourseInterface) {
    this.id = obj.id
    this.title = obj.title
    this.status = obj.status
    this.order = obj.order
    this.name = obj.name
    this.domainId = obj.domainId
  }

  isInputCorrect(): boolean {
    return (
      this.title.length > 0 && this.order > 0 && this.domainId !== undefined && this.name.length > 0
    )
  }
}

export class LearningCourseUrlOptions implements ILearningCourseUrlOptions {
  withModules: boolean
  withGrains: boolean
  withSupports: boolean
  withDomains: boolean
  status?: LearningEnums.Status[]

  constructor(obj: ILearningCourseUrlOptions) {
    this.withModules = obj.withModules
    this.withGrains = obj.withGrains
    this.withSupports = obj.withSupports
    this.withDomains = obj.withDomains
    this.status = obj.status
  }

  getUrlOptions(): string {
    let url = ''

    url += `?withModules=${this.withModules}`
    url += `&withGrains=${this.withGrains}`
    url += `&withSupports=${this.withSupports}`
    url += `&withDomains=${this.withDomains}`

    if (this.status && this.status.length > 0) {
      url += `&status=${this.status.join(',')}`
    }

    return url
  }
}
