import type { ILearningGrainInput } from '@/assets/types/learning/LearningGrainTypes'
import API from '@/services/API'
import { LearningGrainResponseDto } from '@/assets/DTO/learning/learningGrain.response.dto'
import { NabooError } from '@/assets/classes/Error'
import { LearningEnums } from '@/assets/types/learning/enums'

export const learningGrains = {
  async createLearningGrain(
    learningGrain: ILearningGrainInput,
    learningModuleId: number,
    learningCourseId: number
  ) {
    try {
      const response = await API().post(
        `learning/courses/${learningCourseId}/modules/${learningModuleId}/grains`,
        learningGrain
      )
      const grain = new LearningGrainResponseDto(response.data)

      return Promise.resolve(grain)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningGrains.ts - createLearningGrain')
        error.setMessage('An error occurred while creating the grain.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'An error occurred while creating the grain.',
          status: 0,
          name: 'Error',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  },

  async updateLearningGrain(
    learningGrain: ILearningGrainInput,
    learningModuleId: number,
    learningCourseId: number
  ) {
    try {
      const response = await API().put(
        `learning/courses/${learningCourseId}/modules/${learningModuleId}/grains/${learningGrain.id}`,
        learningGrain
      )
      const grain = new LearningGrainResponseDto(response.data)

      return Promise.resolve(grain)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningGrains.ts - updateLearningGrain')
        error.setMessage('An error occurred while updating the grain.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'An error occurred while updating the grain.',
          status: 0,
          name: 'Error',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  },

  async updateGrainStatus(
    status: LearningEnums.Status,
    grainId: number,
    learningModuleId: number,
    learningCourseId: number
  ) {
    try {
      const response = await API().put(
        `learning/courses/${learningCourseId}/modules/${learningModuleId}/grains/${grainId}/status`,
        { status }
      )
      const grain = new LearningGrainResponseDto(response.data)

      return Promise.resolve(grain)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningGrains.ts - updateGrainStatus')
        error.setMessage('An error occurred while updating the grain status.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'An error occurred while updating the grain status.',
          status: 0,
          name: 'Error',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  }
}
