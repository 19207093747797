<script lang="ts" setup>
/*
 *
 *
 * IMPORTS
 *
 * */

// PRIMEVUE
import { default as PInputText } from 'primevue/inputtext'
import { default as PInputNumber } from 'primevue/inputnumber'
import { default as PButton } from 'primevue/button'
import { default as PBadge } from 'primevue/badge'
import { default as PMessage } from 'primevue/message'

// VUE, VUE ROUTER, ETC...
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

// Custom components and types
import type { ILearningGrain } from '@/assets/types/learning/LearningGrainTypes'
import type { ILearningSupport } from '@/assets/types/learning/LearningSupportTypes'
import { deepClone } from '@/assets/utils'
import type { ILearningModuleInput } from '@/assets/types/learning/LearningModuleTypes'
import { LearningEnums } from '@/assets/types/learning/enums'
import type { ILearningQuiz } from '@/assets/types/learning/learningQuiz'

const router = useRouter()

const model = defineModel('modelValue', {
  type: Object as () => ILearningGrain,
  default: {} as ILearningGrain
})

//EMITS
const emits = defineEmits([
  'cancel',
  'delete',
  'start-grain-update',
  'validate',
  'publish',
  'update'
])

// PROPS
const props = defineProps<{
  isEditing: boolean
  learningModule: ILearningModuleInput
  learningCourseId: number
}>()

// DATA
const learningGrain = ref({} as ILearningGrain)

// COMPUTED
const lastSupportIsFilled = computed(() => {
  if (model.value.learningSupports && model.value.learningSupports.length > 0)
    return model.value.learningSupports?.[model.value.learningSupports?.length - 1].url !== ''

  return true
})

const quizPreQuestions = computed(() => {
  if (model.value.quizPre) return getQuizCompletion(model.value.quizPre)
  return {
    text: 'Quiz vide',
    color: 'secondary'
  }
})

const quizPostQuestions = computed(() => {
  if (model.value.quizPost) return getQuizCompletion(model.value.quizPost)
  return {
    text: 'Quiz vide',
    color: 'secondary'
  }
})

const canPublishGrain = computed(() => {
  // To be published a grain needs to have at least 5 questions in the pre and post quizzes and at least one learning support
  return (
    model.value.quizPre?.questions.length >= 5 &&
    model.value.quizPost?.questions.length >= 5 &&
    model.value.learningSupports?.length > 0 &&
    model.value.status === LearningEnums.Status.DRAFT
  )
})

const blockingPublishTexts = computed(() => {
  const texts = []

  if (model.value.quizPre?.questions.length < 5) {
    let text = 'Quiz de début de grain : '

    text += `${5 - model.value.quizPre?.questions.length} question${
      5 - model.value.quizPre?.questions.length > 1 ? 's' : ''
    } manquante${5 - model.value.quizPre?.questions.length > 1 ? 's' : ''} pour publier le grain.`
    texts.push(text)
  }

  if (model.value.quizPost?.questions.length < 5) {
    let text = 'Quiz de fin de grain : '

    text += `${5 - model.value.quizPost?.questions.length} question${
      5 - model.value.quizPre?.questions.length > 1 ? 's' : ''
    } manquante${5 - model.value.quizPost?.questions.length > 1 ? 's' : ''} pour publier le grain.`
    texts.push(text)
  }

  if (model.value.learningSupports?.length === 0) {
    texts.push('Le grain doit contenir au moins un support pour publier le grain.')
  }

  return texts
})

// METHODS
function addNewSupport() {
  model.value.learningSupports ??= []

  model.value.learningSupports.push({
    type: 'GENIALLY',
    estimatedTime: 10,
    url: ''
  } as ILearningSupport)
}

function validate() {
  emits('validate', model.value)
}

function editQuiz(type: 'pre' | 'post') {
  switch (type) {
    case 'pre':
      router.push({
        name: 'learning-grain-update-quiz',
        query: {
          quizType: LearningEnums.QuizType.PRE
        },
        params: {
          grainId: model.value.id,
          moduleId: props.learningModule.id,
          id: props.learningCourseId,
          quizId: model.value.quizPre.id
        }
      })
      break
    case 'post':
      router.push({
        name: 'learning-grain-update-quiz',
        query: {
          quizType: LearningEnums.QuizType.POST
        },
        params: {
          grainId: model.value.id,
          moduleId: props.learningModule.id,
          id: props.learningCourseId,
          quizId: model.value.quizPost.id
        }
      })
      break
  }
}

function getQuizCompletion(quiz: ILearningQuiz) {
  const numberOfQuestions = quiz.questions.length
  let text
  let color: string

  if (numberOfQuestions === 0) {
    text = 'Quiz vide'
    color = 'secondary'
  } else {
    text = `${numberOfQuestions} question${numberOfQuestions > 1 ? 's' : ''}`
    color = 'warning'
  }

  if (numberOfQuestions > 4) color = 'success'

  return {
    text,
    color
  }
}

// HAPPENS ON MOUNT
onMounted(() => {
  learningGrain.value = deepClone(model.value)
})

// WATCHERS
</script>

<template>
  <div
    class="border-1 border-dashed border-round border-300 p-3 block w-full text-color-secondary mt-3 relative"
    :class="[{ 'bg-blue-50': isEditing }, { 'p-input-filled': !isEditing }]"
  >
    <div class="align-items-center w-full mb-2" :class="[{ flex: !isEditing }]">
      <template v-if="!isEditing">
        <h4 class="text-color block font-bold">{{ modelValue.title }}</h4>

        <p-badge
          class="ml-auto"
          :value="LearningEnums.statusLabel(modelValue.status)"
          :severity="LearningEnums.statusColor(modelValue.status)"
        />
        <p-button
          text
          icon="pi pi-pencil"
          class="ml-2"
          severity="secondary"
          @click="$emit('start-grain-update')"
        />
        <p-button text icon="pi pi-trash" severity="secondary" @click="$emit('delete')" />
      </template>
      <template v-else>
        <h6>Titre du grain</h6>
        <p-input-text class="w-full mb-3" :disabled="!isEditing" v-model="modelValue.title" />
      </template>
    </div>

    <div>
      <h6>Nombre de point</h6>
      <p-input-number
        class="w-full mb-3"
        :disabled="!isEditing"
        v-model="modelValue.points"
        @update="$emit('update', modelValue)"
      />
    </div>

    <div class="grid grid-nogutter gap-3">
      <div class="col-12 flex align-items-center">
        <h6>Quiz de début de grain</h6>
        <p-badge class="ml-1" :value="quizPreQuestions.text" :severity="quizPreQuestions.color" />
        <p-button
          class="ml-auto"
          label="Editer le quiz"
          @click="editQuiz('pre')"
          outlined
          :disabled="!model.quizPre"
        />
      </div>
      <div class="col-12 flex align-items-center">
        <h6>Quiz de fin de grain</h6>
        <p-badge class="ml-1" :value="quizPostQuestions.text" :severity="quizPostQuestions.color" />
        <p-button
          class="ml-auto"
          label="Editer le quiz"
          @click="editQuiz('post')"
          outlined
          :disabled="!model.quizPost"
        />
      </div>
    </div>

    <div>
      <div v-if="model.learningSupports?.length" class="flex w-full pr-5">
        <div class="pr-2 w-full">
          <h6 class="mt-3">Lien des supports</h6>
        </div>
        <div class="pl-2 w-10rem">
          <h6 class="mt-3">Durée (en min)</h6>
        </div>
      </div>

      <template v-for="(support, index) in modelValue.learningSupports" :key="`support_${index}`">
        <div class="flex w-full mb-2">
          <div class="pr-2 w-full">
            <div class="p-inputgroup flex-1" :class="[{ 'p-disabled': !isEditing }]">
              <span class="p-inputgroup-addon">www</span>
              <template v-if="model.learningSupports">
                <p-input-text
                  class="w-full"
                  v-model="support.url"
                  @update="$emit('update', modelValue)"
                />
              </template>
            </div>
          </div>
          <div class="w-10rem pl-2">
            <template v-if="model.learningSupports">
              <p-input-number
                class="w-full"
                input-class="w-full"
                :disabled="!isEditing"
                v-model="support.estimatedTime"
                @update="$emit('update', modelValue)"
              />
            </template>
          </div>
          <p-button
            icon="pi pi-trash"
            class="ml-auto"
            severity="secondary"
            :disabled="!isEditing"
            @click="modelValue.learningSupports.splice(index, 1)"
            text
          />
        </div>
      </template>
      <p-button
        class="mt-3"
        outlined
        v-if="isEditing"
        :disabled="!lastSupportIsFilled"
        label="Ajouter un nouveau support"
        @click="addNewSupport()"
      />
    </div>

    <div v-if="isEditing" class="flex w-full justify-content-between mt-5">
      <p-button severity="secondary" label="Annuler" @click="$emit('cancel')" />
      <p-button label="Valider" @click="() => validate()" />
    </div>
  </div>

  <p-message
    class="mt-2 mb-0"
    :pt="{ wrapper: { class: 'py-1' } }"
    severity="warn"
    v-for="(text, i) in blockingPublishTexts"
    :key="`block_${i}`"
    ><p>{{ text }}</p>
  </p-message>
  <p-button
    label="Publier"
    class="mt-3 ml-auto"
    :disabled="!canPublishGrain"
    @click="$emit('publish', learningGrain.id)"
  />
</template>
